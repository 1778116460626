import * as _ from "lodash"
const SET_SIDEBAR_MENU = "SET_SIDEBAR_MENU"
const SET_SIDEBAR_MOBILE_MENU = "SET_SIDEBAR_MOBILE_MENU"
const SET_SIDEBAR_MOBILE_MENU_SIDEBAR = "SET_SIDEBAR_MOBILE_MENU_SIDEBAR"
const RESET_SIDEBAR = "RESET_SIDEBAR"

export const state = () => ({
  sidebarMenu: null,
  sidebarMobileMenu: null,
  sidebarMobileMenuSidebar: null,
  sidebarMenuAccount: {},
  hasBuiltSidebarMenu: false,
  hasBuiltGlobalSidebarMenu: false,
})

const getters = {
  getSidebarMenu: (state) => state.sidebarMenu,
  getSidebarMobileMenu: (state) => state.sidebarMobileMenu,
  getSidebarMobileMenuSidebar: (state) => state.sidebarMobileMenuSidebar,
  getHasBuiltGlobalSidebarMenu: (state) => state.hasBuiltGlobalSidebarMenu,
}

export const mutations = {
  [SET_SIDEBAR_MENU](state, payload) {
    state.sidebarMenu = payload.sidebarMenu
    state.hasBuiltSidebarMenu = payload.hasBuiltSidebarMenu
    state.hasBuiltGlobalSidebarMenu = Object.hasOwnProperty.call(payload, "hasBuiltGlobalSidebarMenu") ? payload.hasBuiltGlobalSidebarMenu : false
  },
  [SET_SIDEBAR_MOBILE_MENU](state, payload) {
    state.sidebarMobileMenu = payload.sidebarMenu
  },
  [SET_SIDEBAR_MOBILE_MENU_SIDEBAR](state, payload) {
    state.sidebarMobileMenuSidebar = payload
  },
  [RESET_SIDEBAR](state) {
    state.sidebarMenu = null
    state.sidebarMobileMenu = null
    state.sidebarMobileMenuSidebar = null
    state.sidebarMenuAccount = {}
    state.hasBuiltSidebarMenu = false
  },
}

export const actions = {
  resetSidebar({ commit, dispatch, rootGetters }) {
    const user = rootGetters["user/getUser"]

    if (user && user.is_super_admin) {
      // Create global admin menu
      dispatch("buildGlobalAdminSidebarMenu")
    } else {
      commit(RESET_SIDEBAR)
    }
  },
  buildSidebarMenu: async ({ dispatch, commit, rootGetters }, payload) => {
    const user = rootGetters["user/getUser"]
    let userLocations = await dispatch('user/userLocationsAndAccess', null, { root: true })
    // let userAccess = dispatch('user/hasFullAccess')
    // console.log("🚀 ~ file: navigation.js ~ line 59 ~ buildSidebarMenu: ~ userAccess", userLocations)
    var sidebarMenu


    if (user.is_super_admin || user.is_website_builder) {
      sidebarMenu = buildSuperAdminSidebarlinks(rootGetters)
    } else {
      sidebarMenu = buildUserSidebarlinks(rootGetters, userLocations.hasFullAccess)
    }

    var mobileSidebarMenu = buildMobileSidebarlinks(rootGetters, commit, userLocations.hasFullAccess)
    commit(SET_SIDEBAR_MENU, { sidebarMenu: _.cloneDeep(sidebarMenu), hasBuiltSidebarMenu: true })
    commit(SET_SIDEBAR_MOBILE_MENU, { sidebarMenu: _.cloneDeep(mobileSidebarMenu), hasBuiltSidebarMenu: true })

    return sidebarMenu
  },
  /**
   * Build global admin menu. This is used on accounts home page, admin dashboard, admin calendar, and any global page
   */
  async buildGlobalAdminSidebarMenu({ state, commit, rootGetters }, payload) {
    const user = rootGetters["user/getUser"]

    // Construct sidenav with global admin
    var sidebarMenu
    if (user.is_super_admin) {
      const globalMenu = buildGlobalAdminSidebarLinks(this._vm.$isAllowed("superSuperAdmin"))
      commit(SET_SIDEBAR_MENU, { sidebarMenu: _.cloneDeep(globalMenu), hasBuiltSidebarMenu: true, hasBuiltGlobalSidebarMenu: true })
    }

    return sidebarMenu
  },
}

function buildMobileSidebarlinks(rootGetters, commit, hasFullAccess) {
  const accountId = rootGetters["user/getSelectedAccountId"]
  const account = rootGetters["accounts/getSelectedAccount"]
  const user = rootGetters["user/getUser"]
  const rhsClientCodes = rootGetters["accounts/getRhsClientCodes"]

  const website = rootGetters["website/getSelectedWebsite"]
  var sidebarMenu = {
    logo: "/logo-insights-formal-temporary.png",
    sidebarPosition: "fixed-left",
    sidebarLinks: [],
  }
  if (account && account.is_onboarding === false) {
    if (!website.is_website_live && !rhsClientCodes.includes(account.client_code)) {
      if (hasFullAccess) {
        addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Locations", "/storage-manager/[account_id]/locations", "map")
      }
      addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Leads", "/leads/[account_id]", "user", rootGetters['features/getFeature']("lead_manager"))
      addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Email Reports", "/dashboard/[account_id]/email-reports", "activity", rootGetters['features/getFeature']("email_reports"))
      if (account && account.use_insights_unit_manager === true) {
        sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildUnitsManager(user, account))
      }
    } else {
      // sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildCompany(user, account, rootGetters))
      if (hasFullAccess) {
        sidebarMenu.sidebarLinks.push({
          name: "Dashboard",
          path: "/dashboard/[account_id]/view-dashboard",
          icon: "pie-chart",
        })
        sidebarMenu.sidebarLinks.push({
          name: "Calendar",
          path: "/dashboard/[account_id]/calendar",
          icon: "calendar",
        })
      }
      addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Leads", "/leads/[account_id]", "user", rootGetters['features/getFeature']("lead_manager"))
      addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Email Reports", "/dashboard/[account_id]/email-reports", "activity", rootGetters['features/getFeature']("email_reports"))
      if (account && account.use_insights_unit_manager === true) {
        sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildUnitsManager(user, account))
      }
      if (hasFullAccess) {
        sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildWebsiteManager(user, account))
        sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildStorageManager(user, account, rhsClientCodes))
      }
      // let items = buildItemsWithTitle(user)
      // sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(items)
    }
  } else if (account && !rhsClientCodes.includes(account.client_code)) {
    addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Locations", "/storage-manager/[account_id]/locations", "map")
  }

  addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Help Center", "https://help.storagepug.com", "book-open", null, true)

  sidebarMenu.sidebarLinks.forEach((item, parentIndex) => {
    // replace [account_id] in path
    if (item.path) {
      item.path = item.path.replace("[account_id]", accountId)
    }
  })

  if (sidebarMenu.sidebarLinks.length > 4) {
    let links = sidebarMenu.sidebarLinks.slice(0, 4)
    let extras = sidebarMenu.sidebarLinks.slice(4, sidebarMenu.sidebarLinks.length)
    sidebarMenu.sidebarLinks = links
    sidebarMenu.sidebarLinks.push({
      name: "More",
      icon: "more-horizontal",
      collapsed: false,
    })

    if (user.is_super_admin || user.is_website_builder) {
      let sidebar = buildSuperAdminSidebarlinks(rootGetters)
      commit(SET_SIDEBAR_MOBILE_MENU_SIDEBAR, sidebar.sidebarLinks)
    } else commit(SET_SIDEBAR_MOBILE_MENU_SIDEBAR, extras)
  }
  else {
    commit(SET_SIDEBAR_MOBILE_MENU_SIDEBAR, _.cloneDeep(sidebarMenu.sidebarLinks))
    sidebarMenu.sidebarLinks.push({
      name: "More",
      icon: "more-horizontal",
      collapsed: false,
    })
  }

  return sidebarMenu
}

function buildUserSidebarlinks(rootGetters, hasFullAccess) {
  const accountId = rootGetters["user/getSelectedAccountId"]
  const account = rootGetters["accounts/getSelectedAccount"]
  const rhsClientCodes = rootGetters["accounts/getRhsClientCodes"]
  const user = rootGetters["user/getUser"]
  const website = rootGetters["website/getSelectedWebsite"]
  var sidebarMenu = {
    logo: "/logo-insights-formal-temporary.png",
    sidebarPosition: "fixed-left",
    sidebarLinks: [],
  }
  if (account && account.is_onboarding === false) {
    if (!website.is_website_live && !rhsClientCodes.includes(account.client_code)) {
      if (hasFullAccess) {
        addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Locations", "/storage-manager/[account_id]/locations", "map")
      }
      addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Leads", "/leads/[account_id]", "user", rootGetters['features/getFeature']("lead_manager"))
      addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Email Reports", "/dashboard/[account_id]/email-reports", "activity", rootGetters['features/getFeature']("email_reports"))
      if (account && account.use_insights_unit_manager === true) {
        sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildUnitsManager(user, account))
      }
    } else {
      // sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildCompany(user, account))
      if (hasFullAccess) {
        sidebarMenu.sidebarLinks.push({
          name: "Dashboard",
          path: "/dashboard/[account_id]/view-dashboard",
          icon: "pie-chart",
        })
        sidebarMenu.sidebarLinks.push({
          name: "Calendar",
          path: "/dashboard/[account_id]/calendar",
          icon: "calendar",
        })
      }
      addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Leads", "/leads/[account_id]", "user", rootGetters['features/getFeature']("lead_manager"))
      addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Email Reports", "/dashboard/[account_id]/email-reports", "activity", rootGetters['features/getFeature']("email_reports"))
      if (hasFullAccess) {
      }

      if (account && account.use_insights_unit_manager === true) {
        sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildUnitsManager(user, account))
      }
      if (hasFullAccess) {
        sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildWebsiteManager(user, account))
        sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(buildStorageManager(user, account, rhsClientCodes))
      }

    }
    if (hasFullAccess) {
      sidebarMenu.sidebarLinks.push({
        name: "Teammates",
        path: "/teammates/[account_id]",
        icon: "users",
      })
    }
  } else {
    addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Locations", "/storage-manager/[account_id]/locations", "map")
  }

  addRoute(user, sidebarMenu.sidebarLinks, user.permissions, "Help Center", "https://help.storagepug.com", "book-open", null, true)

  sidebarMenu.sidebarLinks.forEach((item, parentIndex) => {
    // replace [account_id] in path
    if (item.path) {
      item.path = item.path.replace("[account_id]", accountId)
    }
  })

  if (sidebarMenu.sidebarLinks.length > 7) {
    let links = sidebarMenu.sidebarLinks.slice(0, 6)
    let extras = sidebarMenu.sidebarLinks.slice(6, sidebarMenu.sidebarLinks.length)
    sidebarMenu.sidebarLinks = links
    sidebarMenu.sidebarLinks.push({
      name: "More",
      icon: "more-horizontal",
      collapsed: false,
      children: extras,
    })
  }


  return sidebarMenu
}

function buildSuperAdminSidebarlinks(rootGetters) {
  const accountId = rootGetters["user/getSelectedAccountId"]
  const account = rootGetters["accounts/getSelectedAccount"]
  const user = rootGetters["user/getUser"]
  var sidebarMenu = {
    logo: "/logo-insights-formal-temporary.png",
    sidebarPosition: "fixed-left",
    sidebarLinks: [],
  }
  var sidebarLinks = []

  if (user.is_super_admin) {
    sidebarLinks.push({
      name: "Company",
      icon: "pie-chart",
      collapsed: false,
      id: 1,
      children: buildCompany(user, account, rootGetters),
    })
  }

  // Set up website builder nav menu
  sidebarLinks.push({
    name: "Website",
    icon: "layout",
    id: 2,
    collapsed: false,
    children: buildWebsiteManager(user, account),
  })

  // Set up units manager nav menu
  if (account && account.use_insights_unit_manager === true) {
    sidebarLinks.push({
      name: "Units",
      icon: "package",
      id: 3,
      children: buildUnitsManager(user, account),
    })
  }
  addRoute(user, sidebarLinks, user.permissions, "Leads", "/leads/[account_id]", "user", rootGetters['features/getFeature']("lead_manager"))
  sidebarLinks.push({
    name: "Storage",
    icon: "archive",
    id: 5,
    children: buildStorageManager(user, account),
  })

  sidebarMenu.sidebarLinks = sidebarLinks
  if (user.is_super_admin) {
    sidebarMenu.sidebarLinks.push({
      name: "Global",
      icon: "compass",
      id: 6,
      children: [
        {
          name: "Amenity lists",
          path: "/global/amenities",
          icon: "tag",
        },
        {
          type: "columns",
          icon: "columns",
          name: "Themes",
          path: "/global/themes",
        },
        {
          name: "Rental form builder",
          path: "/global/form-builder",
          icon: "sidebar",
        },
        {
          name: "Queries",
          path: "/global/queries",
          icon: "bar-chart-2",
        },
        {
          name: "Changelog",
          path: "/global/changelog",
          icon: "rss",
        },
        {
          type: "feature",
          icon: "zap",
          name: "Features",
          path: "/global/features",
        },
      ],
    })
  } else if (user.is_website_builder) {
    sidebarMenu.sidebarLinks.push({
      name: "Global",
      icon: "compass",
      id: 7,
      children: [
        {
          name: "Amenity lists",
          path: "/global/amenities",
          icon: "tag",
        },
        {
          name: "Rental form builder",
          path: "/global/form-builder",
          icon: "sidebar",
        },
      ],
    })
  }

  let items = buildItemsWithTitle(user)
  sidebarMenu.sidebarLinks = sidebarMenu.sidebarLinks.concat(items)

  sidebarMenu.sidebarLinks.forEach((item, parentIndex) => {
    if ((_.isNil(item.children) || !item.children.length) && item.path) {
      // replace [account_id] in path
      if (item.path) {
        item.path = item.path.replace("[account_id]", accountId)
      }
    }
    else if (item.children && item.children.length) {
      item.children.forEach((child, i) => {
        // console.log('%d: %s', i, value)
        if (child.path) {
          child.path = child.path.replace("[account_id]", accountId)
        }
      })
    }
  })
  return sidebarMenu
}

function buildGlobalAdminSidebarLinks(isSuperSuperAdmin) {
  const sidebarMenu = {
    logo: "/logo-insights-formal-temporary.png",
    sidebarPosition: "fixed-left",
    sidebarLinks: [],
  }

  sidebarMenu.sidebarLinks.push({
    path: "/accounts",
    name: "Clients",
    icon: "umbrella",
  })
  sidebarMenu.sidebarLinks.push({
    icon: "pie-chart",
    name: "Adm Dash",
    path: "/accounts/admin-dashboard",
  })
  sidebarMenu.sidebarLinks.push({
    icon: "columns",
    name: "Onboarding",
    path: "/accounts/onboarding/dashboard",
  })
  sidebarMenu.sidebarLinks.push({
    icon: "sliders",
    name: "On Reports",
    path: "/accounts/onboarding/reports",
  })
  sidebarMenu.sidebarLinks.push({
    icon: "calendar",
    name: "Adm Cal",
    path: "/accounts/admin-calendar",
  })
  sidebarMenu.sidebarLinks.push({
    icon: "user",
    name: "Users",
    path: "/users",
  })

  // Links for global actions
  const globalChildren = [
    {
      icon: "tag",
      name: "Amenity lists",
      path: "/global/amenities",
    },
    {
      type: "columns",
      icon: "columns",
      name: "Themes",
      path: "/global/themes",
    },
    {
      icon: "sidebar",
      name: "Rental form builder",
      path: "/global/form-builder",
    },
    {
      icon: "briefcase",
      name: "Client Value",
      path: "/global/client-value",
    },
    {
      icon: "codepen",
      name: "Component Breakdown",
      path: "/global/component-breakdown",
    },
    {
      icon: "search",
      name: "Website Page Search",
      path: "/global/page-search",
    },
    {
      type: "feature",
      icon: "zap",
      name: "Features",
      path: "/global/features",
    },
  ]

  // Only display clubhouse to superSuperAdmins
  if (isSuperSuperAdmin) {

    globalChildren.push({
      icon: "send",
      name: "Email Reports",
      path: "/global/email-reports",
    })
    globalChildren.push({
      icon: "users",
      name: "Clubhouse",
      path: "/global/clubhouse",
    })
    globalChildren.push({
      icon: "bar-chart-2",
      name: "Queries",
      path: "/global/queries",
    })
    globalChildren.push({
      name: "Changelog",
      path: "/global/changelog",
      icon: "rss",
    })
  }

  sidebarMenu.sidebarLinks.push({
    icon: "globe",
    name: "Global",
    id: 1,
    children: globalChildren,
  })

  return sidebarMenu
}

function buildItemsWithTitle(user) {
  let items = []
  if (user.is_super_admin) {
    items.push({
      name: "SUPPORT",
      type: "heading",
    })
    items.push({
      name: "History",
      path: "/history/[account_id]",
      icon: "clipboard",
    })
    items.push({
      name: "Teammates",
      path: "/teammates/[account_id]",
      icon: "users",
    })
    items.push({
      name: "IMPORT",
      type: "heading",
    })
    // items.push({
    //   type: "event",
    //   event_name: "copy_client_website",
    //   name: "Copy Client",
    //   icon: "copy"
    // })
  } else {
    let found = _.find(user.permissions, function (p) {
      if (p && p.permission && p.permission.name) return p.permission.name === "History"
      else return false
    })
    if (found && found.actions.includes("view")) {
      items.push({
        name: "SUPPORT",
        type: "heading",
      })
      items.push({
        name: "History",
        path: "/history/[account_id]",
        icon: "clipboard",
      })
    }
  }

  return items
}

function buildUnitsManager(user, account) {
  var routes = []
  // console.log(JSON.stringify(user.permissions, null, 2))
  addRoute(user, routes, user.permissions, "Units", "/units-manager/[account_id]/view-units", "archive")
  addRoute(user, routes, user.permissions, "Inventory", "/units-manager/[account_id]/inventory", "shopping-bag")
  return routes
}

function buildStorageManager(user, account, rhsClientCodes = []) {
  var routes = []

  if (account && !rhsClientCodes.includes(account.client_code)) {
    addRoute(user, routes, user.permissions, "Locations", "/storage-manager/[account_id]/locations", "map")
  }
  if (account && account.use_insights_unit_manager === false) {
    addRoute(user, routes, user.permissions, "View Units", "/storage-manager/[account_id]/units", "package")
    addRoute(user, routes, user.permissions, "Discounts", "/storage-manager/[account_id]/discounts", "tag")
    addRoute(user, routes, user.permissions, "Leases", "/storage-manager/[account_id]/leases", "file-text")
  }
  addRoute(user, routes, user.permissions, "Global settings", "/storage-manager/[account_id]/global-settings", "sliders")
  // addRoute(user, routes, user.permissions, "Location settings", "/storage-manager/[account_id]/location-settings", "settings")
  return routes
}

function buildWebsiteManager(user, account) {
  var routes = []
  addRoute(user, routes, user.permissions, "Website editor", "/website-manager/[account_id]/editor", "monitor")
  addRoute(user, routes, user.permissions, "Global styles", "/website-manager/[account_id]/global-styles", "pen-tool")
  addRoute(user, routes, user.permissions, "Website options", "/website-manager/[account_id]/options", "grid")
  addRoute(user, routes, user.permissions, "Popups", "/website-manager/[account_id]/popups", "shopping-bag")
  addRoute(user, routes, user.permissions, "Image gallery", "/website-manager/[account_id]/gallery", "image")
  addRoute(user, routes, user.permissions, "SEO", "/website-manager/[account_id]/seo", "trending-up")
  addRoute(user, routes, user.permissions, "Navigation", "/website-manager/[account_id]/navigation", "columns")
  addRoute(user, routes, user.permissions, "Reviews", "/website-manager/[account_id]/reviews", "message-square")
  addRoute(user, routes, user.permissions, "Notifications", "/website-manager/[account_id]/notifications", "mail")

  addRoute(user, routes, user.permissions, "FAQs and Storage Tips", "/website-manager/[account_id]/faqs", "help-circle")
  // addRoute(user, routes, user.permissions, "Blog posts", "/website-manager/[account_id]/blog-posts", "file-text")
  addRoute(user, routes, user.permissions, "Unit cache", "/website-manager/[account_id]/unit-cache", "database")
  if (account && account.use_insights_unit_manager === false) {
  }
  return routes
}

function buildCompany(user, account, rootGetters) {
  var routes = []
  addNoWebBuilderRoute(user, routes, user.permissions, "Dashboard", "/dashboard/[account_id]/view-dashboard", "pie-chart")
  // calendar
  addNoWebBuilderRoute(user, routes, user.permissions, "Calendar", "/dashboard/[account_id]/calendar", "calendar")
  // email report schedules
  if (user.is_super_admin || user.is_website_builder === true) {
    addNoWebBuilderRoute(user, routes, user.permissions, "Email Reports", "/dashboard/[account_id]/email-reports", "activity", rootGetters['features/getFeature']("email_reports"))
    addNoWebBuilderRoute(user, routes, user.permissions, "Information", "/home/[account_id]/about", "file")
  }
  // addNoWebBuilderRoute(user, sidebarLinks, user.permissions, "Email Reports", "/dashboard/[account_id]/email-reports", "activity")
  return routes
}

// badge can be "beta" or "coming soon"
function addRoute(user, routes, permissions, name, path, icon, feature = null, isExternal = false) {
  let routeData = { name, path: isExternal ? undefined : path, icon, feature, href: isExternal ? path : undefined, target: isExternal ? "_blank" : undefined }
  if (user.is_super_admin === true || user.is_website_builder === true) {
    routes.push(routeData)
  }
  else if (["leads", "email reports", "help center"].includes(name.toLowerCase())) {
    /**
     * ! Everyone has permission to Lead Manager, Email Reports, and the Help Center
     */
    routes.push(routeData)
  }
  else {
    let found = _.find(permissions, (p) => p && p.permission && p.permission.name === name)

    if (found && found.actions.includes("view")) {
      routes.push(routeData)
    }
  }
}
function addNoWebBuilderRoute(user, routes, permissions, name, path, icon, feature = null, isExternal = false) {
  let routeData = { name, path: isExternal ? undefined : path, icon, feature, href: isExternal ? path : undefined, target: isExternal ? "_blank" : undefined }
  if (user.is_super_admin === true) {
    routes.push(routeData)
  }
  else if (["leads", "email reports", "help center"].includes(name.toLowerCase())) {
    /**
     * ! Everyone has permission to Lead Manager, Email Reports, and the Help Center
     */
    routes.push(routeData)
  }
  else {
    let found = _.find(permissions, (p) => p && p.permission && p.permission.name === name)

    if (found && found.actions.includes("view")) {
      routes.push(routeData)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
