import _ from "lodash"
import * as uuid from "uuid/v1"
import Vue from "vue"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const SettingApi = ApiFactory.get("setting")
const LocationApi = ApiFactory.get("location")
const HourApi = ApiFactory.get("hour")
const AmenityApi = ApiFactory.get("amenity")

// Mutation Constants
const ADD_LOCATION = "ADD_LOCATION"
const DELETE_LOCATION = "DELETE_LOCATION"
const IS_NEW_LOCATION = "IS_NEW_LOCATION"
const UPDATE_LOCATION = "UPDATE_LOCATION"
const UPDATE_LOCATION_HOURS = "UPDATE_LOCATION_HOURS"
const UPDATE_LOCATION_REVIEW_SETTINGS = "UPDATE_LOCATION_REVIEW_SETTINGS"
const UPDATE_LOCATION_SETTINGS = "UPDATE_LOCATION_SETTINGS"
const UPDATE_LOCATION_URL_SLUG = "UPDATE_LOCATION_URL_SLUG"
const UPDATE_LOCATION_RHS_URL_SLUG = "UPDATE_LOCATION_RHS_URL_SLUG"
const REMOVE_LOCATION_SETTINGS = "REMOVE_LOCATION_SETTINGS"
const SET_LOCATIONS = "SET_LOCATIONS"
const SET_LOCATIONS_ADDRESS = "SET_LOCATIONS_ADDRESS"
const SET_LOCATIONS_ADDRESS_COLOR_MAP = "SET_LOCATIONS_ADDRESS_COLOR_MAP"
const CLEAR_LOCATIONS_ADDRESS = "CLEAR_LOCATIONS_ADDRESS"
const SET_SELECTED = "SET_SELECTED"
const SET_AMENITIES = "SET_AMENITIES"

/**
 * Locations store array
 */
const state = () => ({
  /**
   * We hard code this for testing purposes, but in production
   * we should have an way to select a location.
   */
  selectedLocation: null,
  selectedLocationId: "", // '23b0a562-3550-4b3a-92c1-869319270e7d', // '424ba8af-c29e-444d-8a1d-89c7cc4ecee5',
  locations: [],
  locationsWithAddress: [],
  // * Used to display a colored badge in leads manager
  locationsWithAddressColorMap: [],
  locationsWithAddressTimeSet: null,
  isNewLocation: false
})

const getters = {
  getLocations: (state) => {
    return state.locations
  },
  getLocationsWithAddress: (state) => {
    return state.locationsWithAddress
  },
  // * Used to display a colored badge in leads manager
  getLocationsWithAddressColorMap: (state) => {
    return state.locationsWithAddressColorMap
  },
  getLocationsWithAddressTimeSet: (state) => {
    return state.locationsWithAddressTimeSet
  },
  getIsNewLocation: (state) => {
    return state.isNewLocation
  },
  /**
   * Get location by selected account id
   */
  getLocationsBySelectedAccountID: (state, getters, rootState, rootGetters) => {
    return state.locations.filter((location) => location.account_id === rootGetters["user/getSelectedAccountId"])
  },
  /**
   * Get location by ID and the selected account ID
   */
  getLocationBySelectedAccountId: ({ state, rootGetters }) => (id) => {
    return state.locations.find((location) => location.id === id && location.account_id === rootGetters["user/getSelectedAccountId"])
  },
  /**
   * Get location by id. This disregards any selected account ID
   */
  getLocationById: (state) => (id) => {
    return state.locations.find((location) => location.id === id)
  },
  /**
   * Return selected location
   */
  getSelectedLocation: (state) => {
    return state.selectedLocation
  },
}

const actions = {
  /**
   * Initialize Vuex with selected account's locations
   */
  setLocations({ commit, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      try {
        // Mutate store
        commit(SET_LOCATIONS, payload)
        return resolve(true)
      } catch (e) {
        Vue.rollbar.error('Store: Error setting locations', e)
        return reject(e)
      }
    })
  },
  getLocations({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account = rootGetters["user/getSelectedAccountId"]
      let relations = "review_settings,review_settings.urls"

      if (payload && payload.relations) {
        relations += "," + payload.relations
      }

      try {
        // Make async API call and mutate store
        const response = await LocationApi.all(account, relations)
        const locations_payload = response.data.data

        // Mutate store
        // commit(SET_LOCATIONS, locations_payload)
        return resolve(locations_payload)
      } catch (e) {
        Vue.rollbar.error('Store: Error getting locations', e)
        return reject(e)
      }
    })
  },
  setLocationsWithAddress({ commit, rootGetters, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      const account = rootGetters["user/getSelectedAccountId"]
      let relations = "address,main_image"

      if (payload && payload.relations) {
        relations += "," + payload.relations
      }

      let locationColors = [
        { bg: "rgba(247, 131, 0, 0.1)", main: "#F78300" },
        { bg: "rgba(0, 133, 227, 0.1)", main: "#0085E3" },
        { bg: "rgba(255, 87, 77, 0.1)", main: "#FF574D" },
        { bg: "rgba(53, 169, 111, 0.1)", main: "#35A96F" },
        { bg: "rgba(134, 71, 179, 0.1)", main: "#8647B3" },
        { bg: "rgba(15, 150, 150, 0.1)", main: "#0F9696" },
        { bg: "rgba(50, 76, 221, 0.1)", main: "#324CDD" },
        { bg: "rgba(16, 180, 209, 0.1)", main: "#10B4D1" },
        { bg: "rgba(217, 72, 171, 0.1)", main: "#D948AB" },
      ]

      try {
        var locations_payload
        let userLocations = await dispatch('user/userLocationsAndAccess', null, { root: true })
        var response = await LocationApi.all(account, relations, null, 200, 'name')
        var filter = await Vue.$vlf.getItem(`filterLocations_${ account }`)

        if (userLocations.hasFullAccess) {
          locations_payload = response.data.data
        }
        else {
          locations_payload = [] // _.cloneDeep(userLocations.locations)
          for (let loc of userLocations.locations) {
            let tmp = _.find(response.data.data, (o) => { return o.id == loc.id })
            locations_payload.push(tmp)
          }
        }

        if (!filter) {
          await Vue.$vlf.setItem(`filterLocations_${ account }`, locations_payload[0])
        }

        // Make async API call and mutate store
        let index = 0
        let colorMap = {}

        for (let loc of locations_payload) {
          if (index < 9) {
            loc.color = locationColors[index]
            colorMap[loc.id] = locationColors[index]
          }
          else if (index == 9) {
            index = 0
            loc.color = locationColors[index]
            colorMap[loc.id] = locationColors[index]
          }
          index++
        }

        // Mutate store
        commit(SET_LOCATIONS_ADDRESS, locations_payload)
        commit(SET_LOCATIONS_ADDRESS_COLOR_MAP, colorMap)
        return resolve(locations_payload)
      } catch (e) {
        Vue.rollbar.error('Store: Error setting locations with address', e)
        return reject(e)
      }
    })
  },
  createNewLocation({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]

      try {
        const res = await LocationApi.createBase(account_id)
        commit(SET_SELECTED, res.data.data)
        commit(IS_NEW_LOCATION, true)
        return resolve(res.data.data)
      } catch (e) {
        Vue.rollbar.error('Store: Error creating new location', e)
        return reject(e)
      }
    })
  },
  updateLocation({ commit, rootGetters, getters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]
      const isNewLocation = getters.getIsNewLocation
      const user = rootGetters["user/getUser"]

      // Added current user to last edited field
      if (user) {
        payload.last_edited_by = user
      }

      try {
        const { data: response } = await LocationApi.create(payload, account_id)

        // Update Location Array
        if (isNewLocation === true) {
          commit(SET_SELECTED, response.data)
          commit(ADD_LOCATION, response.data)
          commit(IS_NEW_LOCATION, false)
        }
        // console.log(response)
        commit(UPDATE_LOCATION, response.data)
        return resolve(response.data)
      } catch (e) {
        console.error(e)
        Vue.rollbar.error('Store: Error updating location', e)
        return reject(e)
      }
    })
  },
  updateLocationHours({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]
      try {
        const res = await HourApi.create(payload.hours, account_id)
        commit(UPDATE_LOCATION_HOURS, payload)
        return resolve(true)
      } catch (e) {
        console.error(e)
        Vue.rollbar.error('Store: Error updating location hours', e)
        return reject(e)
      }
    })
  },
  setSelectedLocationById({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account = rootGetters["user/getSelectedAccountId"]
      const relations = "hours,hours.items,address,settings,settings.announcement_bar_image,software_variables,amenities,amenities.image,amenities.amenity,amenities.amenity.image,media,review_settings,review_settings.urls,meta_image,main_image,main_logo"

      try {
        // Make async API call and mutate store
        const response = await LocationApi.one(payload, account, relations)
        const locations_payload = response.data.data

        // Mutate store
        commit(SET_SELECTED, locations_payload)
        return resolve(true)
      } catch (e) {
        Vue.rollbar.error('Store: Error setting selected location', e)
        return reject(e)
      }
    })
  },
  updateLocationSettings({ rootGetters, dispatch, getters }, payload) {
    return new Promise(async (resolve, reject) => {
      const allSettings = []
      const accountID = rootGetters["user/getSelectedAccountId"]

      // Added current user to last edited field
      const user = rootGetters["user/getUser"]

      for (const group of payload.settingGroups) {
        allSettings.push(group.settings)
        if (group.settings.id === null || group.settings.id === undefined) {
          group.settings.id = uuid()
        }

        if (user) {
          group.settings.last_edited_by = user
        }
      }

      try {
        let { data: res } = await SettingApi.create(allSettings, accountID, "locations")
        // console.log(res)
      } catch (e) {
        console.error("Error Update Location Settings: ", e)
      }
      return resolve(true)
    })
  },
  removeLocationSettings({ rootGetters, dispatch, getters }, payload) {
    return new Promise(async (resolve, reject) => {
      const accountID = rootGetters["user/getSelectedAccountId"]

      try {
        payload.locations = []
        let data = await SettingApi.update(payload, accountID, "locations")
        let { data: res } = await SettingApi.delete(payload.id, accountID)
        // console.log(data, res)
      } catch (e) {
        console.error(e)
      }
      return resolve(true)
    })
  },
  /**
   * Update location slug
   */
  updateUrlSlug({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const account_id = rootGetters["user/getSelectedAccountId"]
        const { data: res } = await LocationApi.updateUrlSlug(account_id, payload.id, payload.url_slug)

        if (res.success === true) {
          commit(UPDATE_LOCATION_URL_SLUG, payload)
        }
        return resolve(res)
      } catch (error) {
        Vue.rollbar.error('Store: Error updating url slug', error)
        return reject(error)
      }
    })
  },
  updateRhsUrlSlug({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const account_id = rootGetters["user/getSelectedAccountId"]
        const { data: res } = await LocationApi.updateRhsUrlSlug(account_id, payload.id, payload.rhs_location_id)

        if (res.success === true) {
          commit(UPDATE_LOCATION_RHS_URL_SLUG, payload)
        }
        return resolve(res)
      } catch (error) {
        Vue.rollbar.error('Store: Error updating RHS url slug', error)
        return reject(error)
      }
    })
  },
  setAmenities({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]
      try {
        const res = await AmenityApi.create(payload.amenities, account_id)
        // console.log(payload)
        commit(SET_AMENITIES, payload)
        return resolve(true)
      } catch (e) {
        console.error(e)
        Vue.rollbar.error('Store: Error setting amenities', e)
        return reject(e)
      }
    })
  },
  /**
   * Remove location from the selected account and update the store
   */
  deleteLocation({ commit, rootGetters }, id) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]

      try {
        const { data: response } = await LocationApi.delete(id, account_id)
        commit(DELETE_LOCATION, id)
        return resolve(response)
      } catch (e) {
        Vue.rollbar.error('Store: Error deleting location', e)
        return reject(e)
      }
    })
  },
}

const mutations = {
  /**
   * Set initial locations to store
   */
  [SET_LOCATIONS](state, payload) {
    // const locations = state.locations
    // state.locations = [...locations, ...payload]
    state.locations = payload
  },
  [SET_LOCATIONS_ADDRESS](state, payload) {
    // const locations = state.locations
    // state.locations = [...locations, ...payload]
    state.locationsWithAddress = payload
    state.locationsWithAddressTimeSet = new Date()
  },
  [SET_LOCATIONS_ADDRESS_COLOR_MAP](state, payload) {
    state.locationsWithAddressColorMap = payload
  },
  [CLEAR_LOCATIONS_ADDRESS](state, payload) {
    // const locations = state.locations
    // state.locations = [...locations, ...payload]
    state.locationsWithAddress = null
    state.locationsWithAddressColorMap = null
    state.locationsWithAddressTimeSet = null
  },
  /**
   * Update state with new locaiton object
   */
  [UPDATE_LOCATION](state, payload) {
    const index = _.findIndex(state.locations, function (o) {
      return o.id === payload.id
    })
    state.locations[index] = payload

    // Update selected location if they are the same
    if (state.selectedLocation && state.selectedLocation.id === payload.id) {
      state.selectedLocation = { ...state.selectedLocation, ...payload }
    }
  },
  [ADD_LOCATION](state, payload) {
    // const index = _.findIndex(state.locations, function (o) {
    //   return o.id === payload.id
    // })
    state.locations.push(payload)
  },
  [DELETE_LOCATION](state, id) {
    const locations = state.locations
    const index = locations.findIndex((n) => n.id === id)

    if (index > -1) {
      locations.splice(index, 1)
    }

    state.locations = locations
  },
  [IS_NEW_LOCATION](state, payload) {
    state.isNewLocation = payload
  },
  [UPDATE_LOCATION_HOURS](state, payload) {
    const index = _.findIndex(state.locations, function (o) {
      return o.id === payload.id
    })
    state.locations[index].hours = payload.hours

    // Update selected location if they are the same
    if (state.selectedLocation && state.selectedLocation.id === payload.id) {
      state.selectedLocation = { ...state.selectedLocation, ...payload }
    }
  },
  [UPDATE_LOCATION_REVIEW_SETTINGS](state, payload) {
    // console.log(payload)
    // const index = _.findIndex(state.locations, function (o) {
    //   return o.id === payload.id
    // })
    if (state.selectedLocation) {
      state.selectedLocation.review_settings = payload.review_settings
    }
  },
  [UPDATE_LOCATION_SETTINGS](state, payload) {
    /**
     payload: {
        id: this.locationId,
        settings: this.settings
      }
     */
    const index = _.findIndex(state.locations, function (o) {
      return o.id === payload.id
    })

    state.locations[index].settings = {
      ...state.locations[index].settings,
      ...payload.settings,
    }
  },
  [UPDATE_LOCATION_URL_SLUG](state, payload) {
    const index = _.findIndex(state.locations, function (o) {
      return o.id === payload.id
    })

    if (state.selectedLocation.id === payload.id) {
      state.selectedLocation.url_slug = payload.url_slug
    }
    state.locations[index].url_slug = payload.url_slug
  },
  [UPDATE_LOCATION_RHS_URL_SLUG](state, payload) {
    const index = _.findIndex(state.locations, function (o) {
      return o.id === payload.id
    })

    if (state.selectedLocation.id === payload.id) {
      state.selectedLocation.rhs_location_id = payload.rhs_location_id
    }
    state.locations[index].rhs_location_id = payload.rhs_location_id
  },
  [REMOVE_LOCATION_SETTINGS](state, payload) {
    const index = _.findIndex(state, function (o) {
      return o.id === payload.id
    })
    state.locations[index].settings = null
  },
  [SET_SELECTED](state, payload) {
    state.selectedLocation = payload
  },
  [SET_AMENITIES](state, { id, amenities }) {
    state.locations.forEach((location, index) => {
      if (location.id === id) {
        state.locations[index] = {
          ...state.locations[index],
          amenities,
        }
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}

// const state = () => [
//   {
//     /**
//      * Richard's notes
//      *
//      * suggested fields
//      * @param array of phone numbers to use -- primary, sms, fax, etc.
//      * @param logo_url maybe need this for management companies, etc.
//      * @param integrations for things like xps, pixel (already here), and podium that would explicitly need to be turned on/off depending on the location
//      * @param offers_portable just here as a note :)
//      */
//     id: 'f757d19a-9814-48a7-a970-043575f51974',
//     account_id: 'f757d19a-9814-48a7-a970-043575f51974',
//     amenities_style: 'color',
//     url_slug: 'desoto-bargain-storage',
//     name: 'Another Desoto Bargain Location',
//     tagline: 'Best storage in Desoto',
//     address: {
//       street_1: '2334 Desoto st',
//       street_2: '',
//       city: 'Atlanta',
//       state_province: 'GA',
//       postal: '80437',
//       lat: '32.62553640000001',
//       lon: '-96.85967749999999',
//       country: 'US'
//     },
//     email: 'richard@storagepug.com',
//     // settings: {
//     //   has_gate: true,
//     //   has_office: true,
//     //   rental_generate_random_gate_code: true,
//     //   fb_pixel_enabled: true,
//     //   enable_portal: true,
//     //   portal_enable_payments: true,
//     //   portal_enable_partial_payments: true,
//     //   portal_payment_disabled_message:
//     //     'Payments are currently under maintenance. Please call the office to make a payment.',
//     //   portal_num_prepay_months: 12,
//     //   enable_reservations: true,
//     //   reservation_num_days_in_advance: 10,
//     //   reservation_use_date_picker: true,
//     //   reservation_days_valid: 2,
//     //   reservation_disabled_message: 'Blah blah.',
//     //   enable_rentals: true,
//     //   rental_require_insurance: true,
//     //   rental_show_insurance: true,
//     //   rental_num_days_in_advance: 20,
//     //   rental_use_date_picker: true,
//     //   rental_show_autopay: true,
//     //   rental_pre_check_autopay: true,
//     //   rental_disabled_message:
//     //     'Rentals are currently under maintenance. Please call the office to make a payment.',
//     //   enable_reviews: true,
//     //   reviews_page_title: '',
//     //   reviews_blurb: '',
//     //   reviews_link_url: '',
//     //   reviews_meta_title: '',
//     //   reviews_meta_description: '',
//     //   enable_announcement_bar: true,
//     //   announcement_bar_text: '',
//     //   disabled_bulletins: ['autopay changes', 'move outs', 'payments'],
//     //
//     //
//     // },
//     software: 'sitelink',
//     software_vars: {
//       corp_code: 'CCEC',
//       corp_username: 'StoragePug:::STORAGEPUG4T67KJDY',
//       license_key: 'STORAGEPUG4T67KJDY',
//       location_code: '234',
//       location_pw: 'testpw'
//     },
//     seo_max_price: 144,
//     seo_min_price: 29,
//     seo_review_rating: 4.94,
//     seo_total_reviews: 98,
//     meta_title: 'This is the website title',
//     meta_tagline: "Hey! I'm the website tagline!!",
//     meta_description:
//       'Put a useful description here, it will show up on google for this location',
//     meta_image: {},
//     social_media: {
//       icon_style: 'rounded-color',
//       facebook: 'facebook',
//       twitter: 'twitter',
//       sparefoot: 'sparefoot',
//       google: 'google',
//       yelp: 'yelp'
//     },
//     payment_types: [
//       {
//         category: 'mastercard',
//         label: 'Master Card',
//         value: 2412
//       },
//       {
//         category: 'visa',
//         label: 'Visa',
//         value: 2413
//       },
//       {
//         category: 'amex',
//         label: 'American Express',
//         value: 2414
//       },
//       {
//         category: 'discover',
//         label: 'Discover',
//         value: 2415
//       }
//     ],
//     fb_pixel: {
//       active: true,
//       id: '361438401097702',
//       product_catelog_id: '27433333424711',
//       product_feed_id: '1205714976232435'
//     },
//     hours: [
//       {
//         type: 'office',
//         showOnGoogleMyBusiness: true,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
//             open: '09:00',
//             close: '18:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr 09:00-18:00'
//           },
//           {
//             id: '2',
//             days: ['Sa'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Sa 09:00-14:00'
//           },
//           {
//             id: '3',
//             days: ['Su'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: true,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: ''
//           }
//         ]
//       },
//       {
//         type: 'gate',
//         showOnGoogleMyBusiness: false,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
//             open: '09:00',
//             close: '22:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr,Sa,Su 09:00-22:00'
//           }
//         ]
//       }
//     ],

//     reviews: [
//       {
//         author: 'Patti Mayonaise',
//         icon:
//           'https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/social%20icons%2Fshadow%2FGoogle%20Plus-min.png?alt=media&token=09886b33-1647-410a-a39f-69a9811066f8',
//         link:
//           'https://google.com/search?q=Bargain+Storage+1817+N+Hampton+Rd.+DeSoto,+TX+75115',
//         rating: 5,
//         show_on_web: true,
//         source: 'google',
//         text: 'best place ever!'
//       }
//     ],

//     features: [
//       {
//         id: '1dd3fb83-4b62-4a2a-af1a-0354c947260b',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       },
//       {
//         id: 'a3fc19e6-1866-4f1c-8863-5df13c41ec54',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       }
//     ]
//   },
//   {
//     id: 'f757d19a-9814-48a7-a970-043575f519',
//     amenities_style: 'circle',
//     account_id: 'f757d19a-9814-48a7-a970-043575f51974',
//     url_slug: 'desoto-bargain-storage',
//     name: 'Desoto',
//     tagline: 'Best storage in Desoto',
//     address: {
//       street_1: '2334 Desoto st',
//       street_2: '',
//       city: 'Atlanta',
//       state_province: 'GA',
//       postal: '80437',
//       lat: '32.62553640000001',
//       lon: '-96.85967749999999',
//       country: 'US'
//     },
//     email: 'richard@storagepug.com',
//     // settings: {
//     //   has_gate: true,
//     //   has_office: true,
//     //   rental_generate_random_gate_code: true,
//     //   fb_pixel_enabled: true,
//     //   enable_portal: true,
//     //   portal_enable_payments: true,
//     //   portal_enable_partial_payments: true,
//     //   portal_payment_disabled_message:
//     //     'Payments are currently under maintenance. Please call the office to make a payment.',
//     //   portal_num_prepay_months: 12,
//     //   enable_reservations: true,
//     //   reservation_num_days_in_advance: 10,
//     //   reservation_use_date_picker: true,
//     //   reservation_days_valid: 2,
//     //   reservation_disabled_message: 'Blah blah.',
//     //   enable_rentals: true,
//     //   rental_require_insurance: true,
//     //   rental_show_insurance: true,
//     //   rental_num_days_in_advance: 20,
//     //   rental_use_date_picker: true,
//     //   rental_show_autopay: true,
//     //   rental_pre_check_autopay: true,
//     //   rental_disabled_message:
//     //     'Rentals are currently under maintenance. Please call the office to make a payment.',
//     //   enable_reviews: true,
//     //   reviews_page_title: '',
//     //   reviews_blurb: '',
//     //   reviews_link_url: '',
//     //   reviews_meta_title: '',
//     //   reviews_meta_description: '',
//     //   enable_announcement_bar: true,
//     //   announcement_bar_text: '',
//     //   disabled_bulletins: ['autopay changes', 'move outs', 'payments'],
//     // },
//     software: 'sitelink',
//     software_vars: {
//       corp_code: 'CCEC',
//       corp_username: 'StoragePug:::STORAGEPUG4T67KJDY',
//       license_key: 'STORAGEPUG4T67KJDY',
//       location_code: '234',
//       location_pw: 'testpw'
//     },
//     seo_max_price: 144,
//     seo_min_price: 29,
//     seo_review_rating: 4.94,
//     seo_total_reviews: 98,
//     meta_title: 'This is the website title',
//     meta_tagline: "Hey! I'm the website tagline!!",
//     meta_description:
//       'Put a useful description here, it will show up on google for this location',
//     meta_image: {},
//     payment_types: [
//       {
//         category: 'mastercard',
//         label: 'Master Card',
//         value: 2412
//       },
//       {
//         category: 'visa',
//         label: 'Visa',
//         value: 2413
//       },
//       {
//         category: 'amex',
//         label: 'American Express',
//         value: 2414
//       },
//       {
//         category: 'discover',
//         label: 'Discover',
//         value: 2415
//       }
//     ],
//     fb_pixel: {
//       active: true,
//       id: '361438401097702',
//       product_catelog_id: '27433333424711',
//       product_feed_id: '1205714976232435'
//     },
//     hours: [
//       {
//         type: 'office',
//         showOnGoogleMyBusiness: true,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
//             open: '09:00',
//             close: '18:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr 09:00-18:00'
//           },
//           {
//             id: '2',
//             days: ['Sa'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Sa 09:00-14:00'
//           },
//           {
//             id: '3',
//             days: ['Su'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: true,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: ''
//           }
//         ]
//       },
//       {
//         type: 'gate',
//         showOnGoogleMyBusiness: false,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
//             open: '09:00',
//             close: '22:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr,Sa,Su 09:00-22:00'
//           }
//         ]
//       }
//     ],
//     reviews: [
//       {
//         author: 'Patti Mayonaise',
//         icon:
//           'https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/social%20icons%2Fshadow%2FGoogle%20Plus-min.png?alt=media&token=09886b33-1647-410a-a39f-69a9811066f8',
//         link:
//           'https://google.com/search?q=Bargain+Storage+1817+N+Hampton+Rd.+DeSoto,+TX+75115',
//         rating: 5,
//         show_on_web: true,
//         source: 'google',
//         text: 'best place ever!'
//       }
//     ],
//     features: [
//       {
//         id: '1dd3fb83-4b62-4a2a-af1a-0354c947260b',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       },
//       {
//         id: 'a3fc19e6-1866-4f1c-8863-5df13c41ec54',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       }
//     ]
//   },
//   {
//     id: 'f757d19a-9814-48a7-a970-043575ffddfs519',
//     amenities_style: 'circle',
//     account_id: 'f757d19a-9814-48a7-a970-043575f51974',
//     url_slug: 'desoto-bargain-storage',
//     name: "Chattanooga's Best Storage",
//     tagline: 'Best storage in Desoto',
//     street_1: '2334 Desoto st',
//     street_2: '',
//     city: 'Atlanta',
//     state: 'GA',
//     zip: '80437',
//     email: 'richard@storagepug.com',
//     phone_number: '8655450080',
//     lat: '32.62553640000001',
//     lon: '-96.85967749999999',
//     // settings: {
//     //   has_gate: true,
//     //   has_office: true,
//     //   rental_generate_random_gate_code: true,
//     //   fb_pixel_enabled: true,
//     //   enable_portal: true,
//     //   portal_enable_payments: true,
//     //   portal_enable_partial_payments: true,
//     //   portal_payment_disabled_message:
//     //     'Payments are currently under maintenance. Please call the office to make a payment.',
//     //   portal_num_prepay_months: 12,
//     //   enable_reservations: true,
//     //   reservation_num_days_in_advance: 10,
//     //   reservation_use_date_picker: true,
//     //   reservation_days_valid: 2,
//     //   reservation_disabled_message: 'Blah blah.',
//     //   enable_rentals: true,
//     //   rental_require_insurance: true,
//     //   rental_show_insurance: true,
//     //   rental_num_days_in_advance: 20,
//     //   rental_use_date_picker: true,
//     //   rental_show_autopay: true,
//     //   rental_pre_check_autopay: true,
//     //   rental_disabled_message:
//     //     'Rentals are currently under maintenance. Please call the office to make a payment.',
//     //   enable_reviews: true,
//     //   reviews_page_title: '',
//     //   reviews_blurb: '',
//     //   reviews_link_url: '',
//     //   reviews_meta_title: '',
//     //   reviews_meta_description: '',
//     //   enable_announcement_bar: true,
//     //   announcement_bar_text: '',
//     //   disabled_bulletins: ['autopay changes', 'move outs', 'payments'],
//     // },
//     software: 'sitelink',
//     software_vars: {
//       corp_code: 'CCEC',
//       corp_username: 'StoragePug:::STORAGEPUG4T67KJDY',
//       license_key: 'STORAGEPUG4T67KJDY',
//       location_code: '234',
//       location_pw: 'testpw'
//     },
//     seo_max_price: 144,
//     seo_min_price: 29,
//     seo_review_rating: 4.94,
//     seo_total_reviews: 98,
//     meta_title: 'This is the website title',
//     meta_tagline: "Hey! I'm the website tagline!!",
//     meta_description:
//       'Put a useful description here, it will show up on google for this location',
//     meta_image: {},
//     payment_types: [
//       {
//         category: 'mastercard',
//         label: 'Master Card',
//         value: 2412
//       },
//       {
//         category: 'visa',
//         label: 'Visa',
//         value: 2413
//       },
//       {
//         category: 'amex',
//         label: 'American Express',
//         value: 2414
//       },
//       {
//         category: 'discover',
//         label: 'Discover',
//         value: 2415
//       }
//     ],
//     fb_pixel: {
//       active: true,
//       id: '361438401097702',
//       product_catelog_id: '27433333424711',
//       product_feed_id: '1205714976232435'
//     },
//     hours: [
//       {
//         type: 'office',
//         showOnGoogleMyBusiness: true,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
//             open: '09:00',
//             close: '18:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr 09:00-18:00'
//           },
//           {
//             id: '2',
//             days: ['Sa'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Sa 09:00-14:00'
//           },
//           {
//             id: '3',
//             days: ['Su'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: true,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: ''
//           }
//         ]
//       },
//       {
//         type: 'gate',
//         showOnGoogleMyBusiness: false,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
//             open: '09:00',
//             close: '22:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr,Sa,Su 09:00-22:00'
//           }
//         ]
//       }
//     ],
//     reviews: [
//       {
//         author: 'Patti Mayonaise',
//         icon:
//           'https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/social%20icons%2Fshadow%2FGoogle%20Plus-min.png?alt=media&token=09886b33-1647-410a-a39f-69a9811066f8',
//         link:
//           'https://google.com/search?q=Bargain+Storage+1817+N+Hampton+Rd.+DeSoto,+TX+75115',
//         rating: 5,
//         show_on_web: true,
//         source: 'google',
//         text: 'best place ever!'
//       }
//     ],
//     features: [
//       {
//         id: '1dd3fb83-4b62-4a2a-af1a-0354c947260b',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       },
//       {
//         id: 'a3fc19e6-1866-4f1c-8863-5df13c41ec54',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       }
//     ]
//   },
//   {
//     id: 'f757d19a-9814-48a7-a970-04357dfssd5f519',
//     amenities_style: 'circle',
//     account_id: 'f757d19a-9814-48a7-a970-043575f51974',
//     url_slug: 'desoto-bargain-storage',
//     name: 'Knoxville Self Storage',
//     tagline: 'Best storage in Desoto',
//     address: {
//       street_1: '2334 Desoto st',
//       street_2: '',
//       city: 'Atlanta',
//       state_province: 'GA',
//       postal: '80437',
//       lat: '32.62553640000001',
//       lon: '-96.85967749999999',
//       country: 'US'
//     },
//     email: 'richard@storagepug.com',
//     // settings: {
//     //   has_gate: true,
//     //   has_office: true,
//     //   rental_generate_random_gate_code: true,
//     //   fb_pixel_enabled: true,
//     //   enable_portal: true,
//     //   portal_enable_payments: true,
//     //   portal_enable_partial_payments: true,
//     //   portal_payment_disabled_message:
//     //     'Payments are currently under maintenance. Please call the office to make a payment.',
//     //   portal_num_prepay_months: 12,
//     //   enable_reservations: true,
//     //   reservation_num_days_in_advance: 10,
//     //   reservation_use_date_picker: true,
//     //   reservation_days_valid: 2,
//     //   reservation_disabled_message: 'Blah blah.',
//     //   enable_rentals: true,
//     //   rental_require_insurance: true,
//     //   rental_show_insurance: true,
//     //   rental_num_days_in_advance: 20,
//     //   rental_use_date_picker: true,
//     //   rental_show_autopay: true,
//     //   rental_pre_check_autopay: true,
//     //   rental_disabled_message:
//     //     'Rentals are currently under maintenance. Please call the office to make a payment.',
//     //   enable_reviews: true,
//     //   reviews_page_title: '',
//     //   reviews_blurb: '',
//     //   reviews_link_url: '',
//     //   reviews_meta_title: '',
//     //   reviews_meta_description: '',
//     //   enable_announcement_bar: true,
//     //   announcement_bar_text: '',
//     //   disabled_bulletins: ['autopay changes', 'move outs', 'payments'],
//     // },
//     software: 'sitelink',
//     software_vars: {
//       corp_code: 'CCEC',
//       corp_username: 'StoragePug:::STORAGEPUG4T67KJDY',
//       license_key: 'STORAGEPUG4T67KJDY',
//       location_code: '234',
//       location_pw: 'testpw'
//     },
//     seo_max_price: 144,
//     seo_min_price: 29,
//     seo_review_rating: 4.94,
//     seo_total_reviews: 98,
//     meta_title: 'This is the website title',
//     meta_tagline: "Hey! I'm the website tagline!!",
//     meta_description:
//       'Put a useful description here, it will show up on google for this location',
//     meta_image: {},
//     payment_types: [
//       {
//         category: 'mastercard',
//         label: 'Master Card',
//         value: 2412
//       },
//       {
//         category: 'visa',
//         label: 'Visa',
//         value: 2413
//       },
//       {
//         category: 'amex',
//         label: 'American Express',
//         value: 2414
//       },
//       {
//         category: 'discover',
//         label: 'Discover',
//         value: 2415
//       }
//     ],
//     fb_pixel: {
//       active: true,
//       id: '361438401097702',
//       product_catelog_id: '27433333424711',
//       product_feed_id: '1205714976232435'
//     },
//     hours: [
//       {
//         type: 'office',
//         showOnGoogleMyBusiness: true,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
//             open: '09:00',
//             close: '18:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr 09:00-18:00'
//           },
//           {
//             id: '2',
//             days: ['Sa'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Sa 09:00-14:00'
//           },
//           {
//             id: '3',
//             days: ['Su'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: true,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: ''
//           }
//         ]
//       },
//       {
//         type: 'gate',
//         showOnGoogleMyBusiness: false,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
//             open: '09:00',
//             close: '22:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr,Sa,Su 09:00-22:00'
//           }
//         ]
//       }
//     ],
//     reviews: [
//       {
//         author: 'Patti Mayonaise',
//         icon:
//           'https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/social%20icons%2Fshadow%2FGoogle%20Plus-min.png?alt=media&token=09886b33-1647-410a-a39f-69a9811066f8',
//         link:
//           'https://google.com/search?q=Bargain+Storage+1817+N+Hampton+Rd.+DeSoto,+TX+75115',
//         rating: 5,
//         show_on_web: true,
//         source: 'google',
//         text: 'best place ever!'
//       }
//     ],
//     features: [
//       {
//         id: '1dd3fb83-4b62-4a2a-af1a-0354c947260b',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       },
//       {
//         id: 'a3fc19e6-1866-4f1c-8863-5df13c41ec54',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       }
//     ],
//     images: [
//       {
//         id: '1',
//         src: 'https://picsum.photos/200?search_by=one',
//         title: 'one',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '2',
//         src: 'https://picsum.photos/200?search_by=two',
//         title: 'Two',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '3',
//         src: 'https://picsum.photos/200?search_by=three',
//         title: 'Three',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '4',
//         src: 'https://picsum.photos/200?search_by=four',
//         title: 'Four',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville', 'florida'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '5',
//         src: 'https://picsum.photos/200?search_by=five',
//         title: 'Five',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville', 'nashville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '6',
//         src: 'https://picsum.photos/200?search_by=six',
//         title: 'Six',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville', 'chattanooga', 'nashville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '7',
//         src: 'https://picsum.photos/200?search_by=six',
//         title: 'Six',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville', 'chattanooga', 'nashville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '8',
//         src: 'https://picsum.photos/200?search_by=six',
//         title: 'Six',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville', 'chattanooga', 'nashville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '9',
//         src: 'https://picsum.photos/200?search_by=six',
//         title: 'Six',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville', 'chattanooga', 'nashville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '10',
//         src: 'https://picsum.photos/200?search_by=six',
//         title: 'Six',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville', 'chattanooga', 'nashville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '11',
//         src: 'https://picsum.photos/200?search_by=one',
//         title: 'one',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '12',
//         src: 'https://picsum.photos/200?search_by=two',
//         title: 'Two',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '13',
//         src: 'https://picsum.photos/200?search_by=three',
//         title: 'Three',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '14',
//         src: 'https://picsum.photos/200?search_by=four',
//         title: 'Four',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville', 'florida'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       },
//       {
//         id: '15',
//         src: 'https://picsum.photos/200?search_by=five',
//         title: 'Five',
//         alt: 'This is the image alt',
//         tags: ['one', 'knoxville', 'nashville'],
//         is_icon: false,
//         is_global: false,
//         type: 'image'
//       }
//     ]
//   },
//   {
//     id: 'f757d19a-9814-48a7-a970-04357dfsdf519',
//     account_id: 'f757d19a-9814-48a7-a970-043575f51974',
//     url_slug: 'desoto-bargain-storage',
//     name: 'New York Self Storage',
//     tagline: 'Best storage in Desoto',
//     address: {
//       street_1: '2334 Desoto st',
//       street_2: '',
//       city: 'Atlanta',
//       state_province: 'GA',
//       postal: '80437',
//       lat: '32.62553640000001',
//       lon: '-96.85967749999999',
//       country: 'US'
//     },
//     email: 'richard@storagepug.com',
//     // settings: {
//     //   has_gate: true,
//     //   has_office: true,
//     //   rental_generate_random_gate_code: true,
//     //   fb_pixel_enabled: true,
//     //   enable_portal: true,
//     //   portal_enable_payments: true,
//     //   portal_enable_partial_payments: true,
//     //   portal_payment_disabled_message:
//     //     'Payments are currently under maintenance. Please call the office to make a payment.',
//     //   portal_num_prepay_months: 12,
//     //   enable_reservations: true,
//     //   reservation_num_days_in_advance: 10,
//     //   reservation_use_date_picker: true,
//     //   reservation_days_valid: 2,
//     //   reservation_disabled_message: 'Blah blah.',
//     //   enable_rentals: true,
//     //   rental_require_insurance: true,
//     //   rental_show_insurance: true,
//     //   rental_num_days_in_advance: 20,
//     //   rental_use_date_picker: true,
//     //   rental_show_autopay: true,
//     //   rental_pre_check_autopay: true,
//     //   rental_disabled_message:
//     //     'Rentals are currently under maintenance. Please call the office to make a payment.',
//     //   enable_reviews: true,
//     //   reviews_page_title: '',
//     //   reviews_blurb: '',
//     //   reviews_link_url: '',
//     //   reviews_meta_title: '',
//     //   reviews_meta_description: '',
//     //   enable_announcement_bar: true,
//     //   announcement_bar_text: '',
//     //   disabled_bulletins: ['autopay changes', 'move outs', 'payments'],
//     // },
//     software: 'sitelink',
//     software_vars: {
//       corp_code: 'CCEC',
//       corp_username: 'StoragePug:::STORAGEPUG4T67KJDY',
//       license_key: 'STORAGEPUG4T67KJDY',
//       location_code: '234',
//       location_pw: 'testpw'
//     },
//     seo_max_price: 144,
//     seo_min_price: 29,
//     seo_review_rating: 4.94,
//     seo_total_reviews: 98,
//     meta_title: 'This is the website title',
//     meta_tagline: "Hey! I'm the website tagline!!",
//     meta_description:
//       'Put a useful description here, it will show up on google for this location',
//     meta_image: {},
//     payment_types: [
//       {
//         category: 'mastercard',
//         label: 'Master Card',
//         value: 2412
//       },
//       {
//         category: 'visa',
//         label: 'Visa',
//         value: 2413
//       },
//       {
//         category: 'amex',
//         label: 'American Express',
//         value: 2414
//       },
//       {
//         category: 'discover',
//         label: 'Discover',
//         value: 2415
//       }
//     ],
//     fb_pixel: {
//       active: true,
//       id: '361438401097702',
//       product_catelog_id: '27433333424711',
//       product_feed_id: '1205714976232435'
//     },
//     hours: [
//       {
//         type: 'office',
//         showOnGoogleMyBusiness: true,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
//             open: '09:00',
//             close: '18:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr 09:00-18:00'
//           },
//           {
//             id: '2',
//             days: ['Sa'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Sa 09:00-14:00'
//           },
//           {
//             id: '3',
//             days: ['Su'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: true,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: ''
//           }
//         ]
//       },
//       {
//         type: 'gate',
//         showOnGoogleMyBusiness: false,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
//             open: '09:00',
//             close: '22:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr,Sa,Su 09:00-22:00'
//           }
//         ]
//       }
//     ],
//     reviews: [
//       {
//         author: 'Patti Mayonaise',
//         icon:
//           'https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/social%20icons%2Fshadow%2FGoogle%20Plus-min.png?alt=media&token=09886b33-1647-410a-a39f-69a9811066f8',
//         link:
//           'https://google.com/search?q=Bargain+Storage+1817+N+Hampton+Rd.+DeSoto,+TX+75115',
//         rating: 5,
//         show_on_web: true,
//         source: 'google',
//         text: 'best place ever!'
//       }
//     ],
//     features: [
//       {
//         id: '1dd3fb83-4b62-4a2a-af1a-0354c947260b',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       },
//       {
//         id: 'a3fc19e6-1866-4f1c-8863-5df13c41ec54',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       }
//     ]
//   },
//   {
//     id: 'f757d19a-9814-48a7-a970-098753498',
//     account_id: 'f757d19a-9814-48a7-a970-098753498',
//     url_slug: 'desoto-bargain-storage',
//     name: "Chattanooga's Best Storage",
//     tagline: 'Best storage in Desoto',
//     address: {
//       street_1: '2334 Desoto st',
//       street_2: '',
//       city: 'Atlanta',
//       state_province: 'GA',
//       postal: '80437',
//       lat: '32.62553640000001',
//       lon: '-96.85967749999999',
//       country: 'US'
//     },
//     email: 'richard@storagepug.com',
//     // settings: {
//     //   has_gate: true,
//     //   has_office: true,
//     //   rental_generate_random_gate_code: true,
//     //   fb_pixel_enabled: true,
//     //   enable_portal: true,
//     //   portal_enable_payments: true,
//     //   portal_enable_partial_payments: true,
//     //   portal_payment_disabled_message:
//     //     'Payments are currently under maintenance. Please call the office to make a payment.',
//     //   portal_num_prepay_months: 12,
//     //   enable_reservations: true,
//     //   reservation_num_days_in_advance: 10,
//     //   reservation_use_date_picker: true,
//     //   reservation_days_valid: 2,
//     //   reservation_disabled_message: 'Blah blah.',
//     //   enable_rentals: true,
//     //   rental_require_insurance: true,
//     //   rental_show_insurance: true,
//     //   rental_num_days_in_advance: 20,
//     //   rental_use_date_picker: true,
//     //   rental_show_autopay: true,
//     //   rental_pre_check_autopay: true,
//     //   rental_disabled_message:
//     //     'Rentals are currently under maintenance. Please call the office to make a payment.',
//     //   enable_reviews: true,
//     //   reviews_page_title: '',
//     //   reviews_blurb: '',
//     //   reviews_link_url: '',
//     //   reviews_meta_title: '',
//     //   reviews_meta_description: '',
//     //   enable_announcement_bar: true,
//     //   announcement_bar_text: '',
//     //   disabled_bulletins: ['autopay changes', 'move outs', 'payments'],
//     // },
//     software: 'sitelink',
//     software_vars: {
//       corp_code: 'CCEC',
//       corp_username: 'StoragePug:::STORAGEPUG4T67KJDY',
//       license_key: 'STORAGEPUG4T67KJDY',
//       location_code: '234',
//       location_pw: 'testpw'
//     },
//     seo_max_price: 144,
//     seo_min_price: 29,
//     seo_review_rating: 4.94,
//     seo_total_reviews: 98,
//     meta_title: 'This is the website title',
//     meta_tagline: "Hey! I'm the website tagline!!",
//     meta_description:
//       'Put a useful description here, it will show up on google for this location',
//     meta_image: {},
//     payment_types: [
//       {
//         category: 'mastercard',
//         label: 'Master Card',
//         value: 2412
//       },
//       {
//         category: 'visa',
//         label: 'Visa',
//         value: 2413
//       },
//       {
//         category: 'amex',
//         label: 'American Express',
//         value: 2414
//       },
//       {
//         category: 'discover',
//         label: 'Discover',
//         value: 2415
//       }
//     ],
//     fb_pixel: {
//       active: true,
//       id: '361438401097702',
//       product_catelog_id: '27433333424711',
//       product_feed_id: '1205714976232435'
//     },
//     hours: [
//       {
//         type: 'office',
//         showOnGoogleMyBusiness: true,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
//             open: '09:00',
//             close: '18:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr 09:00-18:00'
//           },
//           {
//             id: '2',
//             days: ['Sa'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Sa 09:00-14:00'
//           },
//           {
//             id: '3',
//             days: ['Su'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: true,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: ''
//           }
//         ]
//       },
//       {
//         type: 'gate',
//         showOnGoogleMyBusiness: false,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
//             open: '09:00',
//             close: '22:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr,Sa,Su 09:00-22:00'
//           }
//         ]
//       }
//     ],
//     reviews: [
//       {
//         author: 'Patti Mayonaise',
//         icon:
//           'https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/social%20icons%2Fshadow%2FGoogle%20Plus-min.png?alt=media&token=09886b33-1647-410a-a39f-69a9811066f8',
//         link:
//           'https://google.com/search?q=Bargain+Storage+1817+N+Hampton+Rd.+DeSoto,+TX+75115',
//         rating: 5,
//         show_on_web: true,
//         source: 'google',
//         text: 'best place ever!'
//       }
//     ],
//     features: [
//       {
//         id: '1dd3fb83-4b62-4a2a-af1a-0354c947260b',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       },
//       {
//         id: 'a3fc19e6-1866-4f1c-8863-5df13c41ec54',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       }
//     ]
//   },
//   {
//     /**
//      * Richard's notes
//      *
//      * suggested fields
//      * @param array of phone numbers to use -- primary, sms, fax, etc.
//      * @param logo_url maybe need this for management companies, etc.
//      * @param integrations for things like xps, pixel (already here), and podium that would explicitly need to be turned on/off depending on the location
//      * @param offers_portable just here as a note :)
//      */
//     id: 'f757d19a-9814-48a7-a970-8724369545',
//     amenities_style: 'circle',
//     account_id: 'f757d19a-9814-48a7-a970-043575f51974',
//     url_slug: 'desoto-bargain-storage',
//     name: "Atlanta's Best Self Storage",
//     tagline: 'Best storage in Desoto',
//     address: {
//       street_1: '2334 Desoto st',
//       street_2: '',
//       city: 'Atlanta',
//       state_province: 'GA',
//       postal: '80437',
//       lat: '32.62553640000001',
//       lon: '-96.85967749999999',
//       country: 'US'
//     },
//     email: 'richard@storagepug.com',
//     software: 'sitelink',
//     software_vars: {
//       corp_code: 'CCEC',
//       corp_username: 'StoragePug:::STORAGEPUG4T67KJDY',
//       license_key: 'STORAGEPUG4T67KJDY',
//       location_code: '234',
//       location_pw: 'testpw'
//     },
//     seo_max_price: 144,
//     seo_min_price: 29,
//     seo_review_rating: 4.94,
//     seo_total_reviews: 98,
//     meta_title: 'This is the website title',
//     meta_tagline: "Hey! I'm the website tagline!!",
//     meta_description:
//       'Put a useful description here, it will show up on google for this location',
//     meta_image: {},
//     payment_types: [
//       {
//         category: 'mastercard',
//         label: 'Master Card',
//         value: 2412
//       },
//       {
//         category: 'visa',
//         label: 'Visa',
//         value: 2413
//       },
//       {
//         category: 'amex',
//         label: 'American Express',
//         value: 2414
//       },
//       {
//         category: 'discover',
//         label: 'Discover',
//         value: 2415
//       }
//     ],
//     fb_pixel: {
//       active: true,
//       id: '361438401097702',
//       product_catelog_id: '27433333424711',
//       product_feed_id: '1205714976232435'
//     },
//     hours: [
//       {
//         type: 'office',
//         showOnGoogleMyBusiness: true,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr'],
//             open: '09:00',
//             close: '18:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr 09:00-18:00'
//           },
//           {
//             id: '2',
//             days: ['Sa'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Sa 09:00-14:00'
//           },
//           {
//             id: '3',
//             days: ['Su'],
//             open: '09:00',
//             close: '14:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: true,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: ''
//           }
//         ]
//       },
//       {
//         type: 'gate',
//         showOnGoogleMyBusiness: false,
//         items: [
//           {
//             id: '1',
//             days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
//             open: '09:00',
//             close: '22:00',
//             isOpen24Hours: false,
//             open24HoursText: 'Open 24 Hours',
//             isClosed: false,
//             closedText: 'Closed',
//             isDisplayText: false,
//             displayText: '',
//             idJsonFormattedText: 'Mo,Tu,We,Th,Fr,Sa,Su 09:00-22:00'
//           }
//         ]
//       }
//     ],
//     reviews: [
//       {
//         author: 'Patti Mayonaise',
//         icon:
//           'https://firebasestorage.googleapis.com/v0/b/storpug-main.appspot.com/o/social%20icons%2Fshadow%2FGoogle%20Plus-min.png?alt=media&token=09886b33-1647-410a-a39f-69a9811066f8',
//         link:
//           'https://google.com/search?q=Bargain+Storage+1817+N+Hampton+Rd.+DeSoto,+TX+75115',
//         rating: 5,
//         show_on_web: true,
//         source: 'google',
//         text: 'best place ever!'
//       }
//     ],
//     features: [
//       {
//         id: '1dd3fb83-4b62-4a2a-af1a-0354c947260b',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       },
//       {
//         id: 'a3fc19e6-1866-4f1c-8863-5df13c41ec54',
//         name: 'Feature 1',
//         title: 'This is the title',
//         icon: {
//           src: 'https://via.placeholder.com/150',
//           title: 'icon title',
//           alt: 'icon alt'
//         }
//       }
//     ]
//   }
// ]
