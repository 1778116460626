import { generateApiUrl } from "@/utils/helpers"
import axios from "axios"
import { startOfDay } from "date-fns"
import Vue from "vue"

export const state = () => ({
  searchString: "",
  searchItems: [],
  filterLocations: null,
  filterStatus: [],
  contact: null,
  contactEventTypes: [],
  contactEventAppliedFilters: {},
  contactLocationIndex: {},
  contactEventDateFilter: null,
})

export const mutations = {
  updateFilterStatus(state, payload) {
    state.filterStatus = payload
  },
  updateFilterLocations(state, payload) {
    if (JSON.stringify(state.filterLocations) != JSON.stringify(payload))
      state.filterLocations = payload
    else {
    }
  },
  updateSearchItems(state, payload) {
    state.searchItems = payload
  },
  updateContact(state, payload) {
    state.contact = payload
  },
  updateSearchString(state, payload) {
    state.searchString = payload
  },
  updateContactEventTypes(state, payload) {
    state.contactEventTypes = payload
  },
  updateContactLocationIndex(state, payload) {
    state.contactLocationIndex = payload
  },
  updateContactEventFilters(state, payload) {
    state.contactEventAppliedFilters = payload
  },
  updateContactEventDateFilter(state, payload) {
    state.contactEventDateFilter = payload
  },
}

export const getters = {
  contact(state) {
    return state.contact
  },
  filterLocations(state) {
    return state.filterLocations
  },
  filterStatus(state) {
    return state.filterStatus
  },
  searchItems(state) {
    return state.searchItems
  },
  searchString(state) {
    return state.searchString
  },
  contactLocationIndex(state) {
    return state.contactLocationIndex
  },
  contactEventAppliedFilters(state) {
    return state.contactEventAppliedFilters
  },
  contactEventTypes(state) {
    return state.contactEventTypes
  },
  contactEventDateFilter(state) {
    return state.contactEventDateFilter
  },
}

const actions = {
  async setContactLocationIndex({ commit, rootGetters }, payload) {
    try {
      let account = rootGetters["accounts/getSelectedAccount"]
      // console.log("setContactLocationIndex -> account", account)
      let user = rootGetters["user/getUser"]

      var queryData = {
        startDate: null,
        endDate: null,
        client_codes: [],
        location_ids: [],
        variables: {
          app_id: account.client_code,
        },
        force_update: true,
      }
      queryData.startDate = startOfDay(new Date())
      queryData.endDate = startOfDay(new Date())
      let url = generateApiUrl(`query/acfa7519-d7ba-4184-a751-2d5aa3fa3d7f/results?skip=0take=10000`)
      let { data: res } = await axios.post(url, queryData, { headers: { "User-ID": user.id } })
      let data = {}
      for (let item of res.data) {
        data[item.contact_email] = item
      }
      commit("updateContactLocationIndex", data)
    } catch (e) {
      console.log("setContactLocationIndex -> e", e)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
