/**
 * Email Notifications State
 */
import * as uuid from "uuid/v1"
import { ApiFactory } from "@/utils/apis/ApiFactory"
import Vue from "vue"
const EmailNotificationEventApi = ApiFactory.get("emailNotificationEvent")
const EmailNotificationApi = ApiFactory.get("emailNotification")

// Mutation types
const SET_EMAIL_NOTIFICATION_EVENTS = "SET_EMAIL_NOTIFICATION_EVENTS"
const SET_EMAIL_NOTIFICATIONS = "SET_EMAIL_NOTIFICATIONS"
const SET_SELECTED_EMAIL_NOTIFICATION_ID = "SET_SELECTED_EMAIL_NOTIFICATION_ID"
const DELETE_RENTAL_NOTIFICATION = "DELETE_RENTAL_NOTIFICATION"
const DELETE_SELECTED_RENTAL_NOTIFICATIONS = "DELETE_SELECTED_RENTAL_NOTIFICATIONS"
const MARK_NOTIFICATIONS_AS_READ = "MARK_NOTIFICATIONS_AS_READ"
const MARK_NOTIFICATIONS_AS_UNREAD = "MARK_NOTIFICATIONS_AS_UNREAD"
const CLONE_EMAIL_NOTIFICATION = "CLONE_EMAIL_NOTIFICATION"
const DELETE_EMAIL_NOTIFICATION = "DELETE_EMAIL_NOTIFICATION"


const state = () => ({
  emailNotifications: [],
  emailNotificationEvents: [],
  /**
   * @TODO In production this will be updated dynamically,
   * for testing purposes it's hardcoded :)
   */
  selectedEmailNotificationId: "",
  selectedEvent: "",
  notifications: [
    {
      id: uuid(),
      created_at: new Date(),
      title: 'New Unit Rented',
      description: `User #1234007 just rented one new unit. It's happening!`,
      has_read: false
    },
    {
      id: uuid(),
      created_at: new Date(),
      title: 'New Unit Rented',
      description: `User #1234007 just rented one new unit. It's happening! Super long description because this new rental is important--More important than the others, of course.`,
      has_read: true
    },
    {
      id: uuid(),
      created_at: new Date(),
      title: 'New Unit Rented',
      description: `User #1234007 just rented one new unit. It's happening!`,
      has_read: false
    },
    {
      id: uuid(),
      created_at: new Date(),
      title: 'New Unit Rented',
      description: `User #1234007 just rented one new unit. It's happening! Super long description because this new rental is important--More important than the others, of course.`,
      has_read: true
    },
    {
      id: uuid(),
      created_at: new Date(),
      title: 'New Unit Rented',
      description: `User #1234007 just rented one new unit. It's happening!`,
      has_read: false
    },
    {
      id: uuid(),
      created_at: new Date(),
      title: 'New Unit Rented',
      description: `User #1234007 just rented one new unit. It's happening! Super long description because this new rental is important--More important than the others, of course.`,
      has_read: true
    },
    {
      id: uuid(),
      created_at: new Date(),
      title: 'New Unit Rented',
      description: `User #1234007 just rented one new unit. It's happening!`,
      has_read: false
    },
    {
      id: uuid(),
      created_at: new Date(),
      title: 'New Unit Rented',
      description: `User #1234007 just rented one new unit. It's happening! Super long description because this new rental is important--More important than the others, of course.`,
      has_read: true
    }
  ],
})

const getters = {
  emailNotifications: (state) => state.emailNotifications,
  emailNotificationEvents: (state) => state.emailNotificationEvents,
  selectedEmailNotification: (state) => {
    if (state.emailNotifications && state.emailNotifications.length && state.selectedEmailNotificationId) {
      const index = state.emailNotifications.findIndex((n) => n.id === state.selectedEmailNotificationId)

      return state.emailNotifications[index]
    }

    return null
  },
  selectedEvent: (state) => {
    if (state.emailNotificationEvents && state.emailNotificationEvents.length && state.selectedEvent) {
      const index = state.emailNotificationEvents.findIndex((e) => e.id === state.selectedEvent)

      return state.emailNotificationEvents[index]
    }

    return null
  },
  getNotifications: (state) => state.notifications,
  // getNotificationById: (state) => (id) => {
  //   return state.notifications.find((notification) => notification.id === id)
  // },
  // getNewRentalNotifications: (state) => state.newRentalNotifications,
  // getNewReservationNotifications: (state) => state.newReservationNotifications,
  // getAllEmailEvents: (state) => {
  //   return [
  //     {
  //       name: 'All Events',
  //       notifications: [
  //         ...state.newRentalNotifications,
  //         ...state.newReservationNotifications
  //       ]
  //     },
  //     {
  //       name: 'New Rental',
  //       notifications: [...state.newRentalNotifications]
  //     },
  //     {
  //       name: 'New Reservation',
  //       notifications: [...state.newReservationNotifications]
  //     }
  //   ]
  // }
}

const actions = {
  /**
   * Set account email notifications
   */
  async setEmailNotifications({ commit, rootGetters }, payload) {
    let account = rootGetters["user/getSelectedAccountId"]
    let relations = "user,locations,events"

    if (payload && payload.account) {
      account = payload.account
    }

    if (payload && payload.relations) {
      relations += "," + payload.relations
    }

    try {
      // Make async API call and mutate store
      const response = await EmailNotificationApi.all(account, relations, null, 1000)
      const notification_email_payload = response.data.data

      // Make sure tags are uniq by name
      commit(SET_EMAIL_NOTIFICATIONS, notification_email_payload)
    } catch (e) {
      Vue.rollbar.error('Store: Error with notifications/setEmailNotifications', e)
    }
  },
  /**
   * Set account email notification events
   */
  setEmailNotificationEvents({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      let accountId = rootGetters["user/getSelectedAccountId"]

      try {
        // Make async API call and mutate store
        const response = await EmailNotificationEventApi.all(accountId, null, null, 1000)
        const notification_event_payload = response.data.data
        // console.log('notification_event_payload: ', notification_event_payload)
        // Make sure tags are uniq by name
        commit(SET_EMAIL_NOTIFICATION_EVENTS, notification_event_payload)
        return resolve()
      } catch (e) {
        Vue.rollbar.error("Store: Error with notifications/setEmailNotificationsEvents", e)
        return reject()
      }
    })
  },
  updateEmailNotification({ dispatch, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // Make async API call and mutate store
        const accountId = rootGetters["user/getSelectedAccountId"]
        const { data: res } = await EmailNotificationApi.create(payload, accountId)
        const notification = res.data
        // console.log('notification: ', notification)
        await dispatch("setEmailNotifications")
        await dispatch("setEmailNotificationEvents")

        // Make sure tags are uniq by name
        return resolve(notification)
      } catch (e) {
        Vue.rollbar.error("Store: Error with notifications/updateEmailNotificationsEvents", e)
        return reject(e)
      }
    })
  },
  /**
   * Mark selected notifications as read
   *
   * Incoming payload is an Array of selected notifications:
   * [
   *  {
   *    id: { String }
   *    title: { String }
   *    description: { String }
   *    is_read: { Boolean }
   *  }
   * ]
   */
  markSelectedNotificationsAsRead({ commit }, payload) {
    commit(MARK_NOTIFICATIONS_AS_READ, payload)
  },
  /**
   * Mark selected notifications as unread
   *
   * Incoming payload is an Array of selected notifications:
   * [
   *  {
   *    id: { String }
   *    title: { String }
   *    description: { String }
   *    is_read: { Boolean }
   *  }
   * ]
   */
  markSelectedNotificationsAsUnread({ commit }, payload) {
    commit(MARK_NOTIFICATIONS_AS_UNREAD, payload)
  },
  /**
   * Delete Rental Notification
   *
   * Incoming payload:
   *    id: { String }
   */
  deleteRentalNotification({ commit }, id) {
    commit(DELETE_RENTAL_NOTIFICATION, id)
  },
  /**
   * Delete Rental Notification
   *
   * Incoming payload is an Array of selected notifications:
   * [
   *  {
   *    id: { String }
   *    title: { String }
   *    description: { String }
   *    is_read: { Boolean }
   *  }
   * ]
   */
  deleteSelectedRentalNotifications({ commit }, payload) {
    commit(DELETE_SELECTED_RENTAL_NOTIFICATIONS, payload)
  },
  /**
   * Clone Email Notification
   *
   * Incoming payload:
   * {
   *    id: { String }
   *    type: { String }
   *    email: { String }
   *    locations: { Array }
   *    events: { Array }
   * }
   */
  cloneEmailNotification({ commit }, payload) {
    // Create new uuid, reset status, views, and comments for cloned post
    const newIdPayload = {
      ...payload,
      id: uuid(),
    }
    newIdPayload.name = newIdPayload.name + " (Clone)"
    commit(CLONE_EMAIL_NOTIFICATION, newIdPayload)
  },
  /**
   * Delete Email Notification
   *
   * Incoming payload Notification Object:
   * {
   *    id: { String }
   *    type: { String }
   *    email: { String }
   *    locations: { Array }
   *    events: { Array }
   * }
   */
  deleteEmailNotification({ commit, rootGetters, dispatch }, payload) {
    // commit(DELETE_EMAIL_NOTIFICATION, payload)
    return new Promise(async (resolve, reject) => {
      try {
        // Make async API call and mutate store
        const accountId = rootGetters["user/getSelectedAccountId"]
        await EmailNotificationApi.delete(payload.id, accountId)
        await dispatch("setEmailNotifications")
        await dispatch("setEmailNotificationEvents")
        return resolve()
      } catch (e) {
        Vue.rollbar.error("Store: Error with notifications/deleteEmailNotification", e)
        return reject(e)
      }
    })
  },
  setSelecedEmailNotificationId({ commit }, payload) {
    commit(SET_SELECTED_EMAIL_NOTIFICATION_ID, payload)
  },
}

const mutations = {
  /**
   * Set store with email notification events
   */
  [SET_EMAIL_NOTIFICATION_EVENTS](state, payload) {
    state.emailNotificationEvents = payload
  },
  /**
   * Set store with email notifications
   */
  [SET_EMAIL_NOTIFICATIONS](state, payload) {
    state.emailNotifications = payload
  },
  [SET_SELECTED_EMAIL_NOTIFICATION_ID](state, payload) {
    state.selectedEmailNotificationId = payload
  },
  /**
   * Delete rental notification
   */
  [DELETE_RENTAL_NOTIFICATION](state, id) {
    const index = state.rentalNotifications.findIndex((notification) => notification.id === id)
    state.rentalNotifications.splice(index, 1)
  },
  /**
   * Delete all selected rental notifications
   */
  [DELETE_SELECTED_RENTAL_NOTIFICATIONS](state, notifications) {
    notifications.forEach((notification) => {
      const index = state.rentalNotifications.findIndex((n) => n.id === notification.id)
      state.rentalNotifications.splice(index, 1)
    })
  },
  /**
   * Mark all selected rental notifications as read
   */
  [MARK_NOTIFICATIONS_AS_READ](state, notifications) {
    notifications.forEach((notification) => {
      const index = state.rentalNotifications.findIndex((n) => n.id === notification.id)
      state.rentalNotifications[index].has_read = true
    })
  },
  /**
   * Mark all selected rental notifications as unread
   */
  [MARK_NOTIFICATIONS_AS_UNREAD](state, notifications) {
    notifications.forEach((notification) => {
      const index = state.rentalNotifications.findIndex((n) => n.id === notification.id)
      state.rentalNotifications[index].has_read = false
    })
  },
  /**
   * Add cloned notification to correct notificaiton state
   */
  [CLONE_EMAIL_NOTIFICATION](state, payload) {
    const notifications = state[payload.type]
    state[payload.type] = [
      ...notifications,
      {
        ...payload,
      },
    ]
  },
  /**
   * Remove deleted the notification from the correct notification state
   */
  [DELETE_EMAIL_NOTIFICATION](state, payload) {
    const notifications = state[payload.type]
    const index = notifications.findIndex((n) => n.id === payload.id)
    notifications.splice(index, 1)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
