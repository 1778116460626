import { ApiFactory } from "@/utils/apis/ApiFactory"
import Vue from "vue"
const SettingApi = ApiFactory.get("setting")
const BrightLocalApi = ApiFactory.get("brightLocal")

const state = () => ({
  settings_id: null,
  // These are defaults to
  permissions: {
    enable_reviews: false,
    is_update_automatically: false,
  },
  apiUrls: [],
})

const getters = {
  permissions: (state) => state.permissions,
  apiUrls: (state) => state.apiUrls,
  settingsId: (state) => state.settings_id,
}

const mutations = {
  setSettingsId(state, payload) {
    state.settings_id = payload
  },
  setPermissions(state, payload) {
    state.permissions = {
      ...state.permissions,
      ...payload,
    }
  },
  setApiUrls(state, payload) {
    state.apiUrls = [...state.apiUrls, ...payload]
  },
}

const actions = {
  createReviewSettingsForLocation({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]
      try {
        const { data: res } = await BrightLocalApi.createBatch(account_id, payload.location_id)
        commit("locations/UPDATE_LOCATION_REVIEW_SETTINGS", res.data.location, {
          root: true,
        })
        return resolve(res.data.location)
      } catch (e) {
        Vue.rollbar.error("Store: Error createReviewSettingsForLocation", e)
        return reject(e)
      }
    })
  },
  createBatch({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]
      try {
        const { data: res } = await BrightLocalApi.createBatch(account_id, payload.location_id)
        commit("locations/UPDATE_LOCATION_REVIEW_SETTINGS", res.data.location, {
          root: true,
        })
        return resolve(res.data.location)
      } catch (e) {
        Vue.rollbar.error('Store: Error createBatch', e)
        return reject(e)
      }
    })
  },
  addUrlToBatch({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]
      try {
        const { data: res } = await BrightLocalApi.addUrl(account_id, payload.location_id, payload.url)
        commit("locations/UPDATE_LOCATION_REVIEW_SETTINGS", res.data.location, {
          root: true,
        })
        return resolve(res.data.location)
      } catch (e) {
        console.error(e)
        Vue.rollbar.error('Store: Error addUrlToBatch', e)
        return reject(e)
      }
    })
  },
  submitBatch({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]
      try {
        const { data: res } = await BrightLocalApi.submitBatch(account_id, payload.location_id)

        commit("locations/UPDATE_LOCATION_REVIEW_SETTINGS", res.data.location, {
          root: true,
        })
        return resolve(res)
      } catch (e) {
        Vue.rollbar.error('Store: Error submitBatch', e)
        return reject(e)
      }
    })
  },
  getResults({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account_id = rootGetters["user/getSelectedAccountId"]
      try {
        const { data: res } = await BrightLocalApi.getResults(account_id, payload.location_id)
        // console.log(res)
        if (res.data.ret !== "loading") {
          commit("locations/UPDATE_LOCATION_REVIEW_SETTINGS", res.data.location, {
            root: true,
          })
        }
        return resolve(res)
      } catch (e) {
        Vue.rollbar.error('Store: Error getResults', e)
        return reject(e)
      }
    })
  },
  setApiUrls({ commit }, payload) {
    commit("setApiUrls", payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
