/**
 * Account pages
 */
import * as uuid from "uuid/v1"
import * as _ from "lodash"
import { recursivelySort, removeAndAlterIds } from "@/dashboard/utils/site-builder"

import { ApiFactory } from "@/utils/apis/ApiFactory"
import Vue from "vue"
const PageApi = ApiFactory.get("page")
const LocationApi = ApiFactory.get("location")

import { generateApiUrl } from "@/utils/helpers"


// Mutation types
const SET_PAGES = "SET_PAGES"
const SET_PAGES_REFERENCE = "SET_PAGES_REFERENCE"
const SET_LOCATION_PAGES_FOR_ROUTE = "SET_LOCATION_PAGES_FOR_ROUTE"
const CLONE_PAGE = "CLONE_PAGE"
const DELETE_PAGE = "DELETE_PAGE"
const UPDATE_PAGE = "UPDATE_PAGE"
const ADD_PAGE = "ADD_PAGE"
const ADD_NEW_LOCATION_PAGE = "ADD_NEW_LOCATION_PAGE"

const state = () => ({
  pages: [],
  selectedLocationPages: [],
  havePagesBeenSet: false,
  pagesReference: [],
  newLocationPage: null,
})

/**
 * Some sweet, canned corned getters for our blog posts.
 */
const getters = {
  /**
   * Return all pages
   */
  getPages: (state) => state.pages,
  getHavePagesBeenSet: (state) => state.havePagesBeenSet,
  getNewLocationPage: (state) => state.newLocationPage,
  getSelectedLocationPages: (state) => state.selectedLocationPages,
  getPagesReference: (state) => state.pagesReference,
  /**
   * Return pages that aren't location pages
   */
  getSitePages: (state) => state.pages.filter((p) => !p.is_location),
  /**
   * Return page by id
   */
  getPageById: (state) => (id) => {
    return state.pages.find((page) => page.id === id)
  },
}

/**
 * @todo
 * - Add action to retrieve all posts from DB
 * - Add action to find post from DB
 */
const actions = {
  /**
   * Initialize Vuex with account pages
   */
  async setPages({ commit, rootGetters }, payload) {
    let page_payload = payload

    if (!payload) {
      const website = rootGetters["website/getSelectedWebsite"]
      const relArray = [
        // 'sections',
        // 'sections.rows',
        // 'sections.rows.column_widths',
        // 'sections.rows.column_widths.components',
        // 'sections.rows.column_widths.components.schema',
        // 'sections.rows.column_widths.components.schema.prop_schemas',
        // 'sections.rows.column_widths.components.style_option',
        // 'sections.rows.column_widths.components.props',
        // 'sections.rows.column_widths.components.props.schema',
        // 'sections.rows.column_widths.components.props.children',
        // 'sections.rows.column_widths.components.props.children.schema',
        // 'sections.rows.column_widths.components.props.children.schema.children',
        // 'sections.rows.column_widths.components.props.children.children',
        // 'sections.rows.column_widths.components.props.children.children.schema',
        // 'sections.rows.column_widths.components.props.children.children.schema.children',
        "style_option",
        "theme",
        "location",
        // 'sections.style_option',
        // 'sections.schema',
        // 'sections.rows.style_option'
      ]
      let relations = relArray.join(",")

      if (payload && payload.relations) {
        relations += "," + payload.relations
      }

      const where = `{"website":{"id":"${ website.id }"}}`

      // Make async API call and mutate store
      const { data: response } = await PageApi.all(null, relations, where, 10000000000000)

      page_payload = response.data
    }

    // Sort pages by index
    // TODO
    // Move this sort on the single page level
    // We really only care about sorting the indexes for a single page
    // when it's being edited in the Website Editor
    const sortedPayload = _.cloneDeep(page_payload)
    sortedPayload.forEach((p) => recursivelySort(p))

    // Mutate store
    if (sortedPayload) {
      commit(SET_PAGES, sortedPayload)
    }
  },
  async setPagesReference({ state, commit, rootGetters }, payload) {
    let page_payload = payload
    let locations = []
    if (!payload) {
      const website = rootGetters["website/getSelectedWebsite"]
      const accountId = rootGetters["user/getSelectedAccountId"]
      const pages = state.pages

      var promises = [LocationApi.all(accountId)]
      if (pages.length === 0) {
        const where = `{"website":{"id":"${ website.id }"}}`

        promises.push(PageApi.all(null, null, where, 10000000000000))
      }
      // Make async API call and mutate store
      const res = await Promise.all(promises)

      locations = res[0].data.data

      if (pages.length === 0) {
        page_payload = res[1].data.data
      } else {
        page_payload = pages
      }
    }

    const sortedPayload = _.cloneDeep(page_payload)
    sortedPayload.forEach((p) => recursivelySort(p))

    const pages = []

    for (const page of sortedPayload) {
      if (page.is_location) {
        const tmpPage = _.clone(page)
        // Find location that matches page
        const loc = locations.find((LOC) => LOC.id === tmpPage.location_id)
        if (loc !== undefined) {
          tmpPage.title = tmpPage.title + " - " + loc.name
          tmpPage.route = `/locations/${ loc.url_slug }${ tmpPage.route }`
          delete tmpPage.id
          pages.push(tmpPage)
        }

        // I commented this out because it was duplicating locations - Nick
        // for (const location of locations) {
        //   const tmpPage = _.clone(page)
        //   tmpPage.title = tmpPage.title + " - " + location.name
        //   tmpPage.route = `/locations/${location.url_slug}${tmpPage.route}`
        //   delete tmpPage.id
        //   pages.push(tmpPage)
        // }
      } else {
        pages.push(page)
      }
    }

    commit(SET_PAGES_REFERENCE, pages)
  },
  /**
   * Clone page.
   *
   * @todo We will add an api call to add cloned page.
   */
  clonePage({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // Get full page object because we have a slimmed down page object
        // on the website editor page table
        const { data: pageResponse } = await PageApi.one(payload.id, null, "website,location,style_option")
        const pageObject = pageResponse.data

        const clonedPage = _.cloneDeep(pageObject)
        // Remove object properties because it should be a fresh start
        // for these fields
        _.unset(clonedPage, "id")
        _.unset(clonedPage, "created_at")
        _.unset(clonedPage, "updated_at")
        _.unset(clonedPage, "version")
        _.unset(clonedPage, "deletedAt")

        // Update routes, name, etc. with cloned strings
        _.set(clonedPage, "route", clonedPage["route"] + "-cloned")
        _.set(clonedPage, "name", clonedPage["name"] + "-cloned")
        _.set(clonedPage, "title", clonedPage["title"] + " (cloned)")

        // Set website id
        // Remove and alter IDs from `sections_object`
        removeAndAlterIds(clonedPage.sections_object)
        removeAndAlterIds(clonedPage.style_option)

        // Add cloned page to database
        const { data: response } = await PageApi.create(clonedPage, null)
        const cloned_page_response = response.data

        // Add cloned page to store
        commit(CLONE_PAGE, {
          oldId: payload.id,
          payload: cloned_page_response,
        })

        return resolve(response)
      } catch (error) {
        console.error("Clone Page: ", error)
        return reject(error)
      }
    })
  },
  clonePageSchema({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // Get full page object because we have a slimmed down page object
        // on the website editor page table

        const clonedPage = _.cloneDeep(payload)
        // Remove object properties because it should be a fresh start
        // for these fie
        _.unset(clonedPage, "id")
        _.unset(clonedPage, "created_at")
        _.unset(clonedPage, "updated_at")
        _.unset(clonedPage, "version")
        _.unset(clonedPage, "deletedAt")

        // Update routes, name, etc. with cloned strings
        _.set(clonedPage, "route", clonedPage["route"] + "-cloned")
        _.set(clonedPage, "name", clonedPage["name"] + "-cloned")
        _.set(clonedPage, "title", clonedPage["title"] + " (cloned)")

        // Set website id
        // Remove and alter IDs from `sections_object`
        removeAndAlterIds(clonedPage.sections_object)
        removeAndAlterIds(clonedPage.style_option)

        // Add cloned page to database
        let url = generateApiUrl(`page-schema`)
        const { data: response } = await Vue.axios.post(url, clonedPage)

        return resolve(response)
      } catch (error) {
        console.error("Clone Page: ", error)
        return reject(error)
      }
    })
  },
  /**
   * Delete page.
   *
   * @todo We will add an api call to delete page.
   */
  deletePage({ commit, rootGetters, dispatch }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: response } = await PageApi.delete(id, null)
        const website = rootGetters["website/getSelectedWebsite"]
        var pages

        // Make async API call and mutate store
        const { data: res } = await PageApi.all(null, "location", JSON.stringify({ is_external: false, website: { id: website.id } }), 100000)
        const sorted = res.data.sort(function (a, b) {
          var nameA = a.name.toLowerCase()
          var nameB = b.name.toLowerCase()
          if (nameA < nameB) {
            // sort string ascending
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0 // default return value (no sorting)
        })
        // Set page store
        await dispatch("setPages", sorted)
        commit(DELETE_PAGE, id)
        return resolve(response)
      } catch (e) {
        Vue.rollbar.error('Store: Error deleting page', e)
        return reject(e)
      }
    })
  },
  updatePage({ commit }, page) {
    commit(UPDATE_PAGE, page)
  },
  addPageToStore({ commit }, page) {
    commit(ADD_PAGE, page)
  },

  addNewLocationPage({ commit }, page) {
    commit(ADD_NEW_LOCATION_PAGE, page)
  },
  setSelectedLocationPages({ commit }, pages) {
    commit(SET_LOCATION_PAGES_FOR_ROUTE, pages)
  },
  /**
   * Create new page and update store
   */
  createPage({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data: response } = await PageApi.create(payload, null)
        const saved_payload = response.data
        commit(ADD_PAGE, saved_payload)
        return resolve(saved_payload)
      } catch (error) {
        Vue.rollbar.error('Store: Error creating page', error)
        return reject(error)
      }
    })
  },
}

/**
 * @todo
 * - Add mutation to update all pages from DB
 */
const mutations = {
  resetPages(state, payload) {
    state.pages = []
    state.selectedLocationPages = []
    state.havePagesBeenSet = false
    state.pagesReference = []
    state.newLocationPage = null
  },
  /**
   * Set initial pages to store
   */
  [SET_PAGES](state, payload) {
    // const pages = state.pages
    state.pages = [...payload]
    state.havePagesBeenSet = true
  },
  [SET_PAGES_REFERENCE](state, payload) {
    // const pages = state.pages
    state.pagesReference = [...payload]
  },
  [SET_LOCATION_PAGES_FOR_ROUTE](state, payload) {
    state.selectedLocationPages = payload
  },
  [ADD_NEW_LOCATION_PAGE](state, payload) {
    // const pages = state.pages
    state.newLocationPage = payload
  },
  /**
   * Add cloned post to Page state
   */
  [CLONE_PAGE](state, { oldId, payload }) {
    const pages = state.pages
    const index = pages.findIndex((c) => c.id === oldId)
    state.pages.splice(index + 1, 0, payload)
  },
  /**
   * Remove deleted the page from the Page state
   */
  [DELETE_PAGE](state, id) {
    const pages = state.pages
    const index = pages.findIndex((n) => n.id === id)
    pages.splice(index, 1)
    state.pages = pages
  },
  [UPDATE_PAGE](state, page) {
    const pages = state.pages
    const index = pages.findIndex((n) => n.id === page.id)
    pages[index] = page
    state.pages = pages
  },
  /**
   * Add page to the pages list
   */
  [ADD_PAGE](state, payload) {
    // const pages = state.pages
    state.pages.push(payload)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
