import _ from "lodash"
import * as uuid from "uuid/v1"
import { AMENITIES } from "@/utils/constants/general"
import Vue from "vue"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const MediaApi = ApiFactory.get("media")
const TagApi = ApiFactory.get("tag")

// Mutation Constants
const SET_MEDIA = "SET_MEDIA"
const SET_MEDIA_COUNT = "SET_MEDIA_COUNT"
const SET_ICONS = "SET_ICONS"
const SET_ICONS_COUNT = "SET_ICONS_COUNT"
const SET_STOCK_COUNT = "SET_STOCK_COUNT"
const SET_STOCK = "SET_STOCK"
const SET_GLOBAL_MEDIA = "SET_GLOBAL_MEDIA"
const SET_GLOBAL_MEDIA_COUNT = "SET_GLOBAL_MEDIA_COUNT"
const SET_MEDIA_TAGS = "SET_MEDIA_TAGS"
const SET_GLOBAL_TAG_FILTERS = "SET_GLOBAL_TAG_FILTERS"
const SET_ACCOUNT_TAGS_SELECTED = "SET_ACCOUNT_TAGS_SELECTED"
const SET_GLOBAL_TAG_FILTERS_OPTIONS = "SET_GLOBAL_TAG_FILTERS_OPTIONS"
const ADD_IMAGES = "ADD_IMAGES"
const SET_ICON_TAG_FILTERS_SELECTED = "SET_ICON_TAG_FILTERS_SELECTED"
const SET_ICON_TAG_FILTER_OPTIONS = "SET_ICON_TAG_FILTER_OPTIONS"
const SET_STOCK_TAG_FILTERS = "SET_STOCK_TAG_FILTERS"
const SET_STOCK_TAG_FILTER_OPTIONS = "SET_STOCK_TAG_FILTER_OPTIONS"
const UPDATE_IS_UPLOADING = "UPDATE_IS_UPLOADING"
const UPDATE_IS_UPLOADING_ERROR = "UPDATE_IS_UPLOADING_ERROR"
const UPDATE_IS_UPLOADING_ERROR_DATA = "UPDATE_IS_UPLOADING_ERROR_DATA"
const UPDATE_IMAGE = "UPDATE_IMAGE"
const DELETE_IMAGE = "DELETE_IMAGE"
const CREATE_TAG = "CREATE_TAG"
const SET_VIEW_IMAGES_TYPE = "SET_VIEW_IMAGES_TYPE"

// This is for testing purposes
// Our icons will come from S3 and not locally
const STYLES = ["circle", "color", "filled", "lined"]
const availableAmenities = []
// STYLES.forEach((style) => {
//   AMENITIES.forEach((amenity) => {
//     availableAmenities.push({
//       id: uuid(),
//       src: require(`@/assets/img/ic-${amenity.name}-${style}.svg`),
//       title: amenity.label,
//       alt: amenity.label,
//       tags: [
//       ],
//       is_icon: true,
//       is_global: true,
//       type: 'picture'
//     })
//   })
// })

export const state = () => ({
  media: [],
  mediaCount: null,
  mediaIcons: [],
  mediaStockPhotos: [],
  mediaIconsCount: null,
  viewImagesType: null,
  mediaStockCount: null,
  stockTagFilters: [],
  stockTagFilterOptions: [],
  mediaIconsFiltersSelected: [],
  mediaIconsFilterOptions: [],
  globalMedia: [],
  globalMediaCount: null,
  tags: [],
  accountTagsSelected: [],
  // The current selected tags to filter by. What would be selected in the dropdown
  globalTagFilters: [],
  globalTagFiltersOptions: [],
  isUploading: false, // If an upload is in progress,
  isUploadingError: false,
  isUploadingErrorData: [],
})

const getters = {
  getAllTags: (state) => state.tags,
  getAccountTagsSelected: (state) => state.accountTagsSelected,
  getMediaCount: (state) => state.mediaCount,
  getGlobalMedia: (state) => state.globalMedia,
  getViewImagesType: (state) => state.viewImagesType,
  getGlobalMediaCount: (state) => state.globalMediaCount,
  getGlobalMedia: (state) => {
    if (state.globalTagFilters.length === 0) {
      return state.globalMedia
    }
    else {
      return state.globalMedia.filter((image) => {
        if (state.globalTagFilters.length === 0) {
          return image
        } else if (_.intersectionBy(image.tags, state.globalTagFilters, "name").length > 0) {
          return image
        }
      })
    }
  },
  getIcons: (state) => {
    return state.mediaIcons.filter((image) => {
      if (state.mediaIconsFiltersSelected.length === 0) {
        return image
      } else if (_.intersectionBy(image.tags, state.mediaIconsFiltersSelected, "name").length > 0) {
        return image
      }
    })
  },
  getStockPhotos: (state) => {
    return state.mediaStockPhotos.filter((image) => {
      if (state.stockTagFilters.length === 0) {
        return image
      } else if (_.intersectionBy(image.tags, state.stockTagFilters, "name").length > 0) {
        return image
      }
    })
  },
  getStockPhotosCount: (state) => state.mediaStockCount,
  getStockTagFilters: (state) => state.stockTagFilters,
  getStockTagFilterOptions: (state) => state.stockTagFilterOptions,
  getIconsCount: (state) => state.mediaIconsCount,
  getGlobalTagFilters: (state) => state.globalTagFilters,
  getGlobalTagFiltersOptions: (state) => state.globalTagFiltersOptions,
  getIconTagFilterOptions: (state) => state.mediaIconsFilterOptions,
  getSelectedIconTagFilters: (state) => state.mediaIconsFiltersSelected,
  /**
   * Get filtered image list from media store
   */
  getImages: (state) => {
    return state.media.filter((image) => {
      if (state.accountTagsSelected && state.accountTagsSelected.length === 0) {
        return image
      } else if (_.intersectionBy(image.tags, state.accountTagsSelected, "name").length > 0) {
        return image
      }
    })
  },
  /**
   * Get state of whether a media file is currently being uploaded
   */
  getIsUploading: (state) => state.isUploading,
  getIsUploadingError: (state) => state.isUploadingError,
  getIsUploadingErrorData: (state) => state.isUploadingErrorData,
  availableStyles: (state) => state.availableStyles,
}

const actions = {
  /**
   * Initialize Vuex with accounts
   */
  setMedia({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      let account = rootGetters["user/getSelectedAccountId"]
      let relations = "tags"

      if (payload && payload.account) {
        account = payload.account
      }

      if (payload && payload.relations) {
        relations += "," + payload.relations
      }

      try {
        // Make async API call and mutate store
        const response = await MediaApi.all(account, relations, null, 90000)

        const media_payload = response.data.data
        // Mutate store
        commit(SET_MEDIA, media_payload)
        commit(SET_MEDIA_COUNT, response.data.num_items)
        return resolve(true)
      } catch (e) {
        Vue.rollbar.error('Store: Error setting media', e)
        return reject(e)
      }
    })
  },
  setGlobalMedia({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {

        // Make async API call and mutate store
        const response = await MediaApi.getGlobalMedia(payload)

        const media_payload = response.data.data
        // Mutate store
        if (payload && payload.icons) {
          commit(SET_ICONS, media_payload)
          commit(SET_ICONS_COUNT, response.data.num_items)
          var tagsIndex = {}
          var tags = []
          // Set the icons tags for filter options
          for (const amenity of media_payload) {
            for (const tag of amenity.tags) {
              if (tagsIndex[tag.name] === undefined) {
                tagsIndex[tag.name] = tag.name
                tags.push(tag)
              }
            }
          }
          commit(SET_ICON_TAG_FILTER_OPTIONS, tags)
        } else if (payload && payload.stock) {
          commit(SET_STOCK, media_payload)
          commit(SET_STOCK_COUNT, response.data.num_items)
          var tagsIndex = {}
          var tags = []
          // Set the icons tags for filter options
          for (const amenity of media_payload) {
            for (const tag of amenity.tags) {
              if (tagsIndex[tag.name] === undefined) {
                tagsIndex[tag.name] = tag.name
                tags.push(tag)
              }
            }
          }
          commit(SET_STOCK_TAG_FILTER_OPTIONS, tags)
        } else {
          var tagsIndex = {}
          var tags = []
          for (const amenity of media_payload) {
            for (const tag of amenity.tags) {
              if (tagsIndex[tag.name] === undefined) {
                tagsIndex[tag.name] = tag.name
                tags.push(tag)
              }
            }
          }
          commit(SET_GLOBAL_MEDIA, media_payload)
          commit(SET_GLOBAL_MEDIA_COUNT, response.data.num_items)
          commit(SET_GLOBAL_TAG_FILTERS_OPTIONS, tags)
        }
        // SET_GLOBAL_TAG_FILTERS

        return resolve(true)
      } catch (e) {
        Vue.rollbar.error("Store: Error with media/setMedia", e)
        return reject(e)
      }
    })
  },
  /**
   * Initialize media tags
   */
  setMediaTags({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      let account = rootGetters["user/getSelectedAccountId"]
      let relations

      if (payload && payload.account) {
        account = payload.account
      }

      if (payload && payload.relations) {
        relations += "," + payload.relations
      }

      try {
        // Make async API call and mutate store
        const response = await TagApi.all(account, relations, null, 90000)
        const tag_payload = response.data.data

        // Make sure tags are uniq by name
        const uniqTags = _.uniqBy(tag_payload, "name")
        commit(SET_MEDIA_TAGS, uniqTags)
        return resolve(true)
      } catch (e) {
        Vue.rollbar.error("Store: Error with media/setMediaTags", e)
        return reject(e)
      }
    })
  },
  updateGlobalTagFilter({ commit }, payload) {
    commit(SET_GLOBAL_TAG_FILTERS, payload)
  },
  updateAccountTagsSelected({ commit }, payload) {

    commit(SET_ACCOUNT_TAGS_SELECTED, payload)
  },
  async updateImage({ commit, rootGetters }, payload) {
    const account = rootGetters["user/getSelectedAccountId"]

    try {
      await MediaApi.update(payload, account)
      commit(UPDATE_IMAGE, payload)

      return Promise.resolve()
    } catch (e) {
      Vue.rollbar.error('Store: Error with updateImage', e)
      return Promise.reject(e)
    }
  },
  async deleteImage({ commit, rootGetters }, payload) {
    const accountId = rootGetters["user/getSelectedAccountId"]

    try {
      await MediaApi.delete(payload.id, accountId)
      commit(DELETE_IMAGE, payload)
      return Promise.resolve()
    } catch (e) {
      Vue.rollbar.error('Store: Error with deleteImage', e)
      return Promise.reject()
    }
  },
  async createTag({ commit, rootGetters }, payload) {
    const accountId = rootGetters["user/getSelectedAccountId"]
    const tag = {
      account: {
        id: accountId,
      },
      id: payload.id,
      name: payload.name,
    }
    try {
      await TagApi.update(tag, accountId)
      commit(CREATE_TAG, tag)
      return Promise.resolve()
    } catch (e) {
      Vue.rollbar.error('Store: Error creating tag', e)
      return Promise.reject(e)
    }
  },
  addImages({ commit }, payload) {
    // Mutate the store to include the added media

    commit(ADD_IMAGES, payload)

    // We will also want to make an API call to add
    // the media to S3 and the database
    // Vuex Reference: https://vuex.vuejs.org/guide/actions.html
  },
  /**
   * Upload media to S3 bucket, which should trigger a Lambda function
   * that resizes image files and add a reference with tags to the database.
   */
  uploadImages({ commit, rootGetters }, payload) {
    // THIS IS NOT BEING USED BUT WE MAY USE IT AGAIN ONE DAY

    return new Promise(async (resolve, reject) => {
      const account = rootGetters["user/getSelectedAccountId"]
      commit(UPDATE_IS_UPLOADING, true)
      try {
        const { data: res } = await MediaApi.uploadImage(payload, account)

        if (res.data.images.length > 0) {
          commit(ADD_IMAGES, res.data.images)
        }
        if (res.data.errors.length > 0) {
          commit(UPDATE_IS_UPLOADING_ERROR, true)
          commit(UPDATE_IS_UPLOADING_ERROR_DATA, res.data.errors)
        }
        commit(UPDATE_IS_UPLOADING, false)

        return resolve(res)
      } catch (e) {
        commit(UPDATE_IS_UPLOADING, false)
        Vue.rollbar.error('Store: Error uploading images', e)
        return reject(e)
      }
    })

    //

    // // Commit action to stop isUploading on success
    // setTimeout(() => {
    //   commit(UPDATE_IS_UPLOADING, false)

    //   // Add toast / notification?
    // }, 5000)
  },
  /**
   * Commit update to toggle isUploading state
   */
  updateIsUploading({ commit }, payload) {
    commit(UPDATE_IS_UPLOADING, payload)
  },
  setIconTagFiltersSelected({ commit }, payload) {
    commit(SET_ICON_TAG_FILTERS_SELECTED, payload)
  },
  setGlobalTagFiltersSelected({ commit }, payload) {
    commit(SET_GLOBAL_TAG_FILTERS, payload)
  },
  setIconTagFilterOptions({ commit }, payload) {
    commit(SET_ICON_TAG_FILTER_OPTIONS, payload)
  },
  setSelectedStockTagFilters({ commit }, payload) {
    commit(SET_STOCK_TAG_FILTERS, payload)
  },
  setViewImagesType({ commit }, payload) {
    commit(SET_VIEW_IMAGES_TYPE, payload)
  },
}

const mutations = {
  resetMedia(state, payload) {
    state.media = []
    state.mediaCount = null
    state.mediaIcons = []
    state.mediaIconsCount = null
    state.mediaIconsFiltersSelected = []
    state.mediaIconsFilterOptions = []
    state.globalMedia = []
    state.globalMediaCount = null
    state.tags = []
    state.globalTagFilters = []
    state.isUploading = false // If an upload is in progress,
      ; (state.isUploadingError = false), (state.isUploadingErrorData = [])
  },
  /**
   * Set initial media to store
   */
  [SET_MEDIA](state, payload) {
    state.media = payload
  },
  [SET_MEDIA_COUNT](state, payload) {
    state.mediaCount = payload
  },
  [SET_ICONS](state, payload) {
    state.mediaIcons = payload
  },
  [SET_STOCK](state, payload) {
    state.mediaStockPhotos = payload
  },
  [SET_ICONS_COUNT](state, payload) {
    state.mediaIconsCount = payload
  },
  [SET_STOCK_COUNT](state, payload) {
    state.mediaStockCount = payload
  },
  [SET_GLOBAL_MEDIA](state, payload) {
    state.globalMedia = payload
  },
  [SET_GLOBAL_MEDIA_COUNT](state, payload) {
    state.globalMediaCount = payload
  },
  /**
   * Set initial media tags to store
   */
  [SET_MEDIA_TAGS](state, payload) {
    state.tags = payload
  },
  [SET_GLOBAL_TAG_FILTERS](state, payload) {
    state.globalTagFilters = _.clone(payload)
  },
  [SET_GLOBAL_TAG_FILTERS_OPTIONS](state, payload) {
    state.globalTagFiltersOptions = _.clone(payload)
  },
  [SET_ACCOUNT_TAGS_SELECTED](state, payload) {
    state.accountTagsSelected = _.clone(payload)
  },
  [SET_ICON_TAG_FILTERS_SELECTED](state, payload) {
    state.mediaIconsFiltersSelected = _.clone(payload)
  },
  [SET_ICON_TAG_FILTER_OPTIONS](state, payload) {
    state.mediaIconsFilterOptions = _.clone(payload)
  },
  [SET_STOCK_TAG_FILTER_OPTIONS](state, payload) {
    state.stockTagFilterOptions = _.clone(payload)
  },
  [SET_STOCK_TAG_FILTERS](state, payload) {
    state.stockTagFilters = _.clone(payload)
  },
  /**
   * Add media to the store
   */
  [ADD_IMAGES](state, payload) {
    // const media = state.media

    // Spread additional media into array
    for (const image of payload) {
      if (image.is_icon === false && image.is_global === false && image.is_stock_photo === false) {
        state.media.unshift(image)
      }
      if (image.is_stock_photo === true) {
        state.mediaStockPhotos.unshift(image)
      }
      if (image.is_icon === true) {
        state.mediaIcons.unshift(image)
      }
      if (image.is_global === true) {
        state.globalMedia.unshift(image)
      }
    }
  },
  [UPDATE_IMAGE](state, payload) {
    if (payload.is_icon === false && payload.is_global === false && payload.is_stock_photo === false) {
      const index = _.findIndex(state.media, (img) => img.id === payload.id)
      state.media.splice(index, 1, payload)
    }
    if (payload.is_icon === true) {
      const index = _.findIndex(state.mediaIcons, (img) => img.id === payload.id)
      state.mediaIcons.splice(index, 1, payload)
    }
    if (payload.is_stock_photo === true) {
      const index = _.findIndex(state.mediaStockPhotos, (img) => img.id === payload.id)
      state.mediaStockPhotos.splice(index, 1, payload)
    }
    if (payload.is_global === true) {
      const index = _.findIndex(state.globalMedia, (img) => img.id === payload.id)
      state.globalMedia.splice(index, 1, payload)
    }
  },
  [DELETE_IMAGE](state, image) {
    if (image.is_icon === false && image.is_global === false && image.is_stock_photo === false) {
      const index = _.findIndex(state.media, (img) => img.id === image.id)
      state.media.splice(index, 1)
    }
    if (image.is_icon === true) {
      const index = _.findIndex(state.mediaIcons, (img) => img.id === image.id)

      state.mediaIcons.splice(index, 1)
    }
    if (image.is_stock_photo === true) {
      const index = _.findIndex(state.mediaStockPhotos, (img) => img.id === image.id)
      state.mediaStockPhotos.splice(index, 1)
    }
    if (image.is_global === true) {
      const index = _.findIndex(state.globalMedia, (img) => img.id === image.id)
      state.globalMedia.splice(index, 1)
    }
  },
  [CREATE_TAG](state, payload) {
    if (state.tags.map((t) => t.name.toLowerCase()).includes(payload.name.toLowerCase())) {
      return
    }
    state.tags = [...state.tags, payload]
  },
  /**
   * Toggle on or off uploading state
   */
  [UPDATE_IS_UPLOADING](state, payload) {
    state.isUploading = payload
  },
  [UPDATE_IS_UPLOADING_ERROR](state, payload) {
    state.isUploadingError = payload
  },
  [UPDATE_IS_UPLOADING_ERROR_DATA](state, payload) {
    state.isUploadingErrorData = payload
  },
  [SET_VIEW_IMAGES_TYPE](state, payload) {
    state.viewImagesType = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
