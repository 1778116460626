/**
 * Units
 */
import { ApiFactory } from "@/utils/apis/ApiFactory"
const UnitApi = ApiFactory.get("unit")
import Vue from "vue"

const state = () => ({
  units: [],
})

const getters = {
  /**
   * Return all units
   */
  getUnits: (state) => state.units,
  /**
   * Return unit by id
   */
  getUnitById: (state) => (id) => {
    return state.units.find((unit) => unit.id === id)
  },
}

const actions = {
  /**
   * Clone unit.
   *
   */
  cloneUnit({ rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const account = rootGetters["user/getSelectedAccountId"]

        const clonedUnit = { ...payload }
        delete clonedUnit.id

        const unit = await UnitApi.create(clonedUnit, account)
        resolve(unit)
      } catch (error) {
        Vue.rollbar.error('Store: Error cloning unit', error, { unit: payload })
        reject(error)
      }
    })
  },
  /**
   * Delete unit.
   *
   */
  deleteUnit({ rootGetters }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const account = rootGetters["user/getSelectedAccountId"]
        await UnitApi.delete(id, account)
        resolve()
      } catch (error) {
        Vue.rollbar.error('Store: Error deleting unit', error, { unit_id: id })
        reject(error)
      }
    })
  },
  updateUnit({ rootGetters }, payload) {
    // console.log(payload)
    return new Promise(async (resolve, reject) => {
      try {
        const account = rootGetters["user/getSelectedAccountId"]
        await UnitApi.update(payload, account)
        resolve()
      } catch (error) {
        Vue.rollbar.error('Store: Error updating unit', error, { unit: payload })
        reject(error)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
}
