const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT"
const SET_USER = "SET_USER"
const SET_LOGGED_IN = "SET_LOGGED_IN"
const HAS_BEEN_SET = "HAS_BEEN_SET"
const SET_CURRENT_FCM_TOKEN = "SET_CURRENT_FCM_TOKEN"
const SET_CURRENT_AUTH_TOKEN = "SET_CURRENT_AUTH_TOKEN"
const SET_ACCOUNT_SWITCHER_ACCOUNTS = "SET_ACCOUNT_SWITCHER_ACCOUNTS"
import Vue from "vue"

const state = () => ({
  // Some kind of concept in the database needs to be established.
  selected_account_id: null,
  user: null,
  currentFcmToken: null,
  currentAuthToken: null,
  isLoggedIn: false,
  hasBeenInitialized: false,
  accountSwitcherAccounts: null
})

const getters = {
  getUser(state, getters) {
    if (state) {
      return state.user
    } else {
      return {}
    }
  },
  getSelectedAccountId(state) {
    return state.selected_account_id
  },
  getHasBeenInitialized(state) {
    return state.hasBeenInitialized
  },
  getCurrentFcmToken(state) {
    return state.currentFcmToken
  },
  getCurrentAuthToken(state) {
    return state.currentAuthToken
  },
  getIsLoggedIn(state) {
    return state.isLoggedIn
  },
  getAccountSwitcherAccounts(state) {
    return state.accountSwitcherAccounts
  },
  /**
   * Get user name initials
   */
  getUserInitials(state) {
    if (state && state.user && state.user.name) {
      const firstLetters = state.user.name.match(/\b\w/g) || []
      let initials = ""

      if (firstLetters.length > 0) {
        initials = (firstLetters.shift() || "") + (firstLetters.pop() || "")
      }

      // Return with it all capitalized
      return initials.toUpperCase()
    }

    return null
  },
}

const actions = {
  userLocationsAndAccess({ state, rootGetters }, payload) {
    return new Promise((resolve, reject) => {
      let account = rootGetters['accounts/getSelectedAccount']
      // commit(SET_SELECTED_ACCOUNT, payload)
      if (account) {
        let locations = _.filter(state.user.locations, (o) => { return o.account_id == account.id })
        return resolve({
          locations: locations,
          hasFullAccess: locations.length === 0
        })
      }
      else {
        return resolve({
          locations: [],
          hasFullAccess: true
        })
      }
    })
  },
  userLocationsAndAccessForAccountId({ state, rootGetters }, accountId) {
    return new Promise((resolve, reject) => {
      // let account = rootGetters['accounts/getSelectedAccount']
      // commit(SET_SELECTED_ACCOUNT, payload)
      if (accountId && state.user.is_super_admin == false && state.user.is_website_builder == false) {
        let locations = _.filter(state.user.locations, (o) => { return o.account_id == accountId })
        return resolve({
          locations: locations,
          hasFullAccess: locations.length === 0
        })
      }
      else {
        return resolve({
          locations: [],
          hasFullAccess: true
        })
      }
    })
  },
  setSelectedAccountId({ commit, dispatch }, payload) {
    // if (payload) {
    commit(SET_SELECTED_ACCOUNT, payload)
    // dispatch('accounts/setSelectedAccount', payload, { root: true })
    // }
  },
  setUser({ commit, dispatch }, payload) {
    if (payload) {
      dispatch("setLoggedIn", true)
    }

    const data = Vue.$storage.set("user", payload)
    // var user = Vue.$storage.get('user')
    // console.log(user, data)
    commit(SET_USER, payload)
  },
  setLoggedIn({ commit }, payload) {
    commit(SET_LOGGED_IN, payload)
  },
  setCurrentFcmToken({ commit }, payload) {
    commit(SET_CURRENT_FCM_TOKEN, payload)
  },
  setCurrentAuthToken({ commit }, payload) {
    commit(SET_CURRENT_AUTH_TOKEN, payload)
  },
  setHasBeenInitialized({ commit }, payload) {
    commit(HAS_BEEN_SET, payload)
  },
  setAccountSwitcherAccounts({ commit }, payload) {
    commit(SET_ACCOUNT_SWITCHER_ACCOUNTS, payload)
  },
}

const mutations = {
  [SET_SELECTED_ACCOUNT](state, payload) {
    state.selected_account_id = payload
  },
  [SET_USER](state, payload) {
    state.user = payload
  },
  [HAS_BEEN_SET](state, payload) {
    state.hasBeenInitialized = payload
  },
  [SET_CURRENT_FCM_TOKEN](state, payload) {
    state.currentFcmToken = payload
  },
  [SET_CURRENT_AUTH_TOKEN](state, payload) {
    state.currentAuthToken = payload
  },
  [SET_LOGGED_IN](state, payload) {
    state.isLoggedIn = payload
  },
  [SET_ACCOUNT_SWITCHER_ACCOUNTS](state, payload) {
    state.accountSwitcherAccounts = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
