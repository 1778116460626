export const state = () => ({
  layout: {
    title: "Dashboard",
    showSearch: true,
    showAlgoliaSearch: false,
    showDropDownLocations: false,
    isBack: false,
    searchPlaceholder: "Search Locations",
    searchProperty: "name",
    searchItems: [{ location: "Alabama" }, { location: "Alaska" }, { location: "Arizona" }, { location: "Arkansas" }, { location: "California" }, { location: "Colorado" }, { location: "Connecticut" }],
    selectedEnterFunction: () => { },
    buttons: [

    ],
    helpLinks: [
      // {
      //   title: "How to get started",
      //   type: "article",
      //   url: "https://storagepug.com"
      // },
      // {
      //   title: "Watch video",
      //   type: "video",
      //   url: "https://storagepug.com"
      // },
      // {
      //   title: "Product tour",
      //   type: "tour",
      //   id: "222794"
      // },
    ]
  },
  filterByOnboarding: false,
  filterByLive: false,
  showTags: false,
  isResetAccount: 0,
  accountTags: [],
  accountTagsRef: {},
  accountTagsIndex: {},
  isDarkMode: "f",
})

const mutations = {
  setDarkMode(state, payload) {
    state.isDarkMode = payload
  },
  setIsResetAccount(state, payload) {
    state.isResetAccount = payload
  },
  updateLayout(state, payload) {
    state.layout = payload
  },
  updateSearchItems(state, payload) {
    state.layout.searchItems = payload
  },
  updateShowTags(state, payload) {
    state.showTags = payload
  },
  updateFilterByOnboarding(state, payload) {
    state.filterByOnboarding = payload
  },
  updateFilterByLive(state, payload) {
    state.filterByLive = payload
  },
  updateAccountTagsRef(state, payload) {
    state.accountTagsRef = payload
  },
  updateAccountTagsIndex(state, payload) {
    state.accountTagsIndex = payload
  },
  updateAccountTags(state, payload) {
    state.accountTags = payload
  },
}

const getters = {
  getIsResetAccount(state) {
    return state.isResetAccount
  },
  getIsDarkMode(state) {
    return state.isDarkMode
  },
  getLayout(state) {
    return state.layout
  },
  getShowTags(state) {
    return state.showTags
  },
  getFilterByOnboarding(state) {
    return state.filterByOnboarding
  },
  getFilterByLive(state) {
    return state.filterByLive
  },
  getSearchItems(state) {
    return state.layout.searchItems
  },
  getAccountTags(state) {
    return state.accountTags
  },
  getAccountTagsRef(state) {
    return state.accountTagsRef
  },
  getAccountTagsIndex(state) {
    return state.accountTagsIndex
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
