import Vue from "vue"
import axios from "axios"
import { generateApiUrl } from "@/utils/helpers"

const SET_SELECTED_UNIT_TO_EDIT = "SET_SELECTED_UNIT_TO_EDIT"
const SET_UNIT_LOCATION_FILTER = "SET_UNIT_LOCATION_FILTER"
const SET_UNIT_SIZE_FILTER = "SET_UNIT_SIZE_FILTER"
const SET_LOCATION_UNITS_EXPIRED_TIME = "SET_LOCATION_UNITS_EXPIRED_TIME"
const SET_ALL_UNITS_FOR_LOCATION = "SET_ALL_UNITS_FOR_LOCATION"
const SET_ALL_UNITS_LOCATION_KEY_INDEX = "SET_ALL_UNITS_LOCATION_KEY_INDEX"

const state = () => ({
  // Some kind of concept in the database needs to be established.
  selectedUnitToEdit: {
    is_published: false,
    width: 5,
    length: 5,
    width_length: "",
    label: "",
    description: "",
    location: {},
    variants: [],
  },
  unitLocationFilters: null,
  unitSizeFilters: [],
  // * load units for lead manager
  allUnitsLocationKeyIndex: null,
  allUnitsLocationExpiredTime: null,
  allUnitsForLocation: null
})

const getters = {
  getAllUnitsForLocation(state) {
    return state.allUnitsForLocation
  },
  getAllUnitsLocationKeyIndex(state) {
    return state.allUnitsLocationKeyIndex
  },
  getallUnitsLocationExpiredTime(state) {
    return state.allUnitsLocationExpiredTime
  },
  getSelectedUnitToEdit(state) {
    return state.selectedUnitToEdit
  },
  getUnitSizeFilters(state) {
    return state.unitSizeFilters
  },
  getUnitLocationFilters(state) {
    return state.unitLocationFilters
  },
}

const actions = {
  setSelectedUnitToEdit({ commit, rootGetters }, payload) {
    commit(SET_SELECTED_UNIT_TO_EDIT, payload)
  },
  setUnitSizeFilters({ commit, rootGetters }, payload) {
    commit(SET_UNIT_SIZE_FILTER, payload)
  },
  setUnitLocationFilters({ commit, rootGetters }, payload) {
    commit(SET_UNIT_LOCATION_FILTER, payload)
  },
  loadUnitsForLocations({ commit, rootGetters, state }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const account_id = rootGetters["user/getSelectedAccountId"]
        // const locations = rootGetters["locations/getLocationsWithAddress"]

        let url = generateApiUrl(`variant-for-table?take=200`)

        let location_ids = []
        let location_index = ""
        let location = await Vue.$vlf.getItem(`filterLocations_${ account_id }`)
        // for (let loc of locations) {
        if (location) {
          location_index += location.id
          location_ids.push(location.id)
        }
        // }

        let shouldRefreshUnits = false

        // * Check to see if location index is different
        if (location_index != state.allUnitsLocationKeyIndex) {
          shouldRefreshUnits = true
          commit(SET_ALL_UNITS_LOCATION_KEY_INDEX, location_index)
          commit(SET_LOCATION_UNITS_EXPIRED_TIME, null)
        }
        // * Check to see if time last retrieved has expired
        if (state.allUnitsLocationExpiredTime == null || state.allUnitsLocationExpiredTime < Date.now()) {
          shouldRefreshUnits = true
          commit(SET_LOCATION_UNITS_EXPIRED_TIME, null)
        }

        if (shouldRefreshUnits) {
          let apiBody = {
            account_id: account_id,
            location_ids: location_ids,
            filter_sizes: [],
          }
          var { data: res } = await axios.post(url, apiBody)
          // console.log("🚀 ~ file: unitManager.js ~ line 97 ~ returnnewPromise ~ res", res)
          commit(SET_ALL_UNITS_FOR_LOCATION, res.data)
          return resolve(res.data)
        }
        else {
          return resolve(true)
        }

      } catch (e) {
        Vue.rollbar.error("Error saving unit in unitManager", e, { unit: payload })
        return reject(e)
      }
    })
  },
  saveUnit({ commit, rootGetters }, payload) {
    // commit(SET_SELECTED_UNIT_TO_EDIT, payload)
    return new Promise(async (resolve, reject) => {
      try {
        const account_id = rootGetters["user/getSelectedAccountId"]

        let url = generateApiUrl(`${ account_id }/product?relations=images,variants,variants.name,variants.amenities,location`)

        payload.account = { id: account_id }

        let { data: res } = await axios.post(url, payload)

        commit(SET_SELECTED_UNIT_TO_EDIT, res.data)

        return resolve(res.data)
      } catch (e) {
        Vue.rollbar.error("Error saving unit in unitManager", e, { unit: payload })
        return reject(e)
      }
    })
  },
}

const mutations = {
  [SET_SELECTED_UNIT_TO_EDIT](state, payload) {
    state.selectedUnitToEdit = payload
  },
  [SET_UNIT_LOCATION_FILTER](state, payload) {
    state.unitLocationFilters = payload
  },
  [SET_UNIT_SIZE_FILTER](state, payload) {
    state.unitSizeFilters = payload
  },
  [SET_ALL_UNITS_FOR_LOCATION](state, payload) {
    state.allUnitsForLocation = payload
  },
  [SET_LOCATION_UNITS_EXPIRED_TIME](state, payload) {
    var ONE_HOUR = 60 * 60 * 1000
    var expires = new Date(new Date().getTime() + ONE_HOUR)
    state.allUnitsLocationExpiredTime = expires
  },
  [SET_ALL_UNITS_LOCATION_KEY_INDEX](state, payload) {
    state.allUnitsLocationKeyIndex = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
