import _ from "lodash"

const UPDATE_SETTINGS = "UPDATE_SETTINGS"
const REMOVE_SETTINGS = "REMOVE_SETTINGS"

const state = () => ({
  categories: [
    { name: "general", title: "General" },
    { name: "rentals", title: "Rentals" },
    { name: "reservations", title: "Reservations" },
    { name: "portal", title: "Portal" },
    { name: "reviews", title: "Reviews" },
    { name: "announcementBar", title: "Announcement Bar" },
  ],
  options: [
    // {
    //   has_office: true,
    //   name: "has_office",
    //   type: "boolean",
    //   is_location: true,
    //   text: "Has an office?",
    //   text_help: "What is this?",
    //   category: "general",
    // },
    // {
    //   rental_generate_random_gate_code: true,
    //   name: 'rental_generate_random_gate_code',
    //   type: 'boolean',
    //   is_location: true,
    //   text: 'Generate random gate code?',
    //   text_help: 'Probabaly does not work',
    //   category: 'general'
    // },
    {
      fb_pixel_enabled: true,
      name: "fb_pixel_enabled",
      type: "boolean",
      is_location: true,
      text: "Enable FB pixel?",
      text_help: "Coming soon!",
      category: "general",
    },
    {
      enable_portal: false,
      name: "enable_portal",
      type: "boolean",
      is_location: true,
      text: "Enable the portal?",
      text_help: "",
      category: "portal",
      is_insights: false,
    },
    {
      portal_enable_payments: true,
      name: "portal_enable_payments",
      type: "boolean",
      is_location: true,
      text: "Enable payments in the portal?",
      text_help: "",
      category: "portal",
      is_insights: false,
    },
    {
      portal_enable_prepayments: true,
      name: "portal_enable_prepayments",
      type: "boolean",
      is_location: true,
      text: "Enable pre payments in the portal?",
      text_help: "",
      category: "portal",
      is_insights: false,
    },
    {
      portal_enable_autopay: true,
      name: "portal_enable_autopay",
      type: "boolean",
      is_location: true,
      text: "Enable autopay in the portal?",
      text_help: "",
      category: "portal",
      is_insights: false,
    },
    {
      portal_enable_move_out: true,
      name: "portal_enable_move_out",
      type: "boolean",
      is_location: true,
      text: "Enable move outs in the portal?",
      text_help: "",
      category: "portal",
      is_insights: false,
    },
    // {
    //   portal_enable_partial_payments: true,
    //   name: 'portal_enable_partial_payments',
    //   type: 'boolean',
    //   is_location: true,
    //   text: 'Enable partial payments in the portal?',
    //   text_help: '',
    //   category: 'portal'
    // },
    {
      portal_phone_form: false,
      name: "portal_phone_form",
      type: "boolean",
      is_location: true,
      text: "Claim account by phone number",
      text_help: "",
      category: "portal",
      is_insights: false,
    },
    {
      portal_payment_disabled_message: "Payments are currently under maintenance. Please call the office to make a payment.",
      name: "portal_payment_disabled_message",
      type: "text",
      is_location: true,
      text: "Portal disabled payments message",
      text_help: "Where does this go?",
      category: "portal",
      is_insights: false,
    },
    {
      portal_num_prepay_months: 12,
      name: "portal_num_prepay_months",
      type: "number",
      is_location: true,
      text: "Number of months allowed to make a prepayment.",
      text_help: "12 is the max",
      category: "portal",
      is_insights: false,
    },
    {
      portal_num_prepay_months: 12,
      name: "portal_claim_account_tooltip",
      type: "text",
      is_location: true,
      text: "Claim account help text",
      text_help: "This will appear when a user claims their account.",
      category: "portal",
      is_insights: false,
    },
    {
      enable_reservations: true,
      name: "enable_reservations",
      type: "boolean",
      is_location: true,
      text: "Enable reservations?",
      text_help: "",
      category: "reservations",
      is_insights: false,
    },
    // {
    //   reservation_use_date_picker: true,
    //   name: 'reservation_use_date_picker',
    //   type: 'boolean',
    //   is_location: true,
    //   text: 'Use date picker for a reservation?',
    //   text_help: '',
    //   category: 'reservations'
    // },
    // {
    //   reservation_num_days_in_advance: 10,
    //   name: 'reservation_num_days_in_advance',
    //   type: 'number',
    //   is_location: true,
    //   text: 'The days a reservation can be made in advance',
    //   text_help: '',
    //   category: 'reservations'
    // },
    {
      reservation_days_valid: 2,
      name: "reservation_days_valid",
      type: "number",
      is_location: true,
      text: "The days a reservation is valid",
      text_help: "",
      category: "reservations",
      is_insights: false,
    },
    {
      reservation_success_message_web: "",
      name: "reservation_success_message_web",
      type: "rich-text",
      is_location: true,
      text: "Reservation Success page message",
      text_help: "The text that shows up on the reservation success page",
      category: "reservations",
      is_insights: false,
    },
    {
      reservation_success_message_email: "",
      name: "reservation_success_message_email",
      type: "rich-text",
      is_location: true,
      text: "Reservation success email message",
      text_help: "This text gets added to the email that is sent after a reservation",
      category: "reservations",
      is_insights: false,
    },
    // {
    //   reservation_disabled_message: 'Blah blah.',
    //   name: 'reservation_disabled_message',
    //   type: 'text',
    //   is_location: true,
    //   text: 'Reservations disabled message',
    //   text_help: '',
    //   category: 'reservations'
    // },
    {
      enable_rentals: true,
      name: "enable_rentals",
      type: "boolean",
      is_location: true,
      text: "Enable rentals?",
      text_help: "This enables rentals.",
      category: "rentals",
    },
    {
      rental_show_insurance: true,
      name: "rental_show_insurance",
      type: "boolean",
      is_location: true,
      text: "Show insurance during a rental?",
      text_help: "",
      category: "rentals",
      is_insights: false,
    },
    {
      rental_require_insurance: true,
      name: "rental_require_insurance",
      type: "boolean",
      is_location: true,
      text: "Require insurance for a rental?",
      text_help: "",
      category: "rentals",
      is_insights: false,
    },
    {
      rental_require_insurance: true,
      name: "rental_use_protection_plans",
      type: "boolean",
      is_location: true,
      text: "Use the word 'Protection Plan' rather than 'Insurance'?",
      text_help: "",
      category: "rentals",
      is_insights: false,
    },
    {
      rental_insurance_tooltip: true,
      name: "rental_insurance_tooltip",
      type: "text",
      is_location: true,
      text: "Insurance Help Text",
      text_help: "This text will appear as a tooltip for the user",
      category: "rentals",
      is_insights: false,
    },
    {
      rental_insurance_tooltip_link: true,
      name: "rental_insurance_tooltip_link",
      type: "text",
      is_location: true,
      text: "Insurance Help Link",
      text_help: "The link to redirect he user to, i.e. 'https://storagepug.com'",
      category: "rentals",
      is_insights: false,
    },
    {
      rental_use_sitelink_esign: true,
      name: "rental_use_sitelink_esign",
      type: "boolean",
      is_location: true,
      text: "Enable SiteLink esign during a rental?",
      text_help: "",
      category: "rentals",
      is_insights: false,
    },
    {
      rental_show_autopay: true,
      name: "rental_show_autopay",
      type: "boolean",
      is_location: true,
      text: "Show autopay during a rental?",
      text_help: "",
      category: "rentals",
      is_insights: false,
    },
    {
      rental_pre_check_autopay: true,
      name: "rental_pre_check_autopay",
      type: "boolean",
      is_location: true,
      text: "Pre check autopay during a rental",
      text_help: "",
      category: "rentals",
      is_insights: false,
    },
    {
      rental_num_days_in_advance: 0,
      name: "rental_num_days_in_advance",
      type: "number",
      is_location: true,
      text: "Number of days a rental can be made in advance",
      text_help: "",
      category: "rentals",
    },
    {
      disable_rentals_on_sunday: false,
      name: "disable_rentals_on_sunday",
      type: "boolean",
      is_location: true,
      text: "Disable rentals on Sunday?",
      text_help: "",
      category: "rentals",
      is_insights: false,
    },
    {
      rental_disabled_message: "Rentals are currently under maintenance. Please call the office to make a payment.",
      name: "rental_disabled_message",
      type: "text",
      is_location: true,
      text: "Rentals disabled message",
      text_help: "",
      category: "rentals",
      is_insights: true,
    },
    {
      rental_show_gate_code_email: false,
      name: "rental_show_gate_code_email",
      type: "boolean",
      is_location: true,
      text: "Show gate code in rental email",
      text_help: "This shows the tenant the gate code generated for them in the email that is sent after a rental",
      category: "rentals",
    },
    {
      rental_success_message_web: "",
      name: "rental_success_message_web",
      type: "rich-text",
      is_location: true,
      text: "Rental success page message",
      text_help: "The text that shows up on the rental success page",
      category: "rentals",
    },
    {
      rental_success_message_email: "",
      name: "rental_success_message_email",
      type: "rich-text",
      is_location: true,
      text: "Rental success email message",
      text_help: "This text gets added to the email that is sent after a rental",
      category: "rentals",
    },
    {
      name: "create_account_form_id",
      type: "multiselect",
      is_location: true,
      text: "Create account rental form",
      text_help: "This form gets added to the rental flow for the create account sign up tab.",
      category: "rentals",
    },
    {
      name: "noke_id",
      type: "text",
      is_location: true,
      text: "Noke ID",
      text_help: "Noke Integration: After a unit is rented, Noke will be notified about the rental.",
      category: "rentals",
      is_insights: false,
    },
    {
      value_pricing: true,
      name: "value_pricing",
      type: "boolean",
      is_location: true,
      text: "Enable value based pricing?",
      text_help: "This will categorize units as 'Good', 'Better', 'Best' based on unit pricing",
      category: "rentals",
    },
    {
      value_pricing_tier_1_label: true,
      name: "value_pricing_tier_1_label",
      type: "text",
      is_location: true,
      text: "Label for lowest value price tier",
      text_help: "e.g.) Standard",
      category: "rentals",
    },
    {
      value_pricing_tier_1_description: true,
      name: "value_pricing_tier_1_description",
      type: "rich-text",
      is_location: true,
      text: "Description for lowest value price tier",
      text_help: "e.g.) Lowest priced unit",
      category: "rentals",
    },
    {
      value_pricing_tier_2_label: true,
      name: "value_pricing_tier_2_label",
      type: "text",
      is_location: true,
      text: "Label for middle value price tier",
      text_help: "e.g.) Best Value",
      category: "rentals",
    },
    {
      value_pricing_tier_2_description: true,
      name: "value_pricing_tier_2_description",
      type: "rich-text",
      is_location: true,
      text: "Description for middle value price tier",
      text_help: "e.g.) Closer to entrance",
      category: "rentals",
    },
    {
      value_pricing_tier_3_label: true,
      name: "value_pricing_tier_3_label",
      type: "text",
      is_location: true,
      text: "Label for highest value price tier",
      text_help: "e.g.) Deluxe",
      category: "rentals",
    },
    {
      value_pricing_tier_3_description: true,
      name: "value_pricing_tier_3_description",
      type: "rich-text",
      is_location: true,
      text: "Description for highest value price tier",
      text_help: "e.g.) Closest to elevator and/or multiple access doors",
      category: "rentals",
    },
    // {
    //   enable_reviews: false,
    //   name: 'enable_reviews',
    //   type: 'boolean',
    //   is_location: true,
    //   text: 'Enable reviews?',
    //   text_help: '',
    //   category: 'reviews'
    // },
    // {
    //   reviews_page_title: '',
    //   name: 'reviews_page_title',
    //   type: 'text',
    //   is_location: true,
    //   text: 'Reviews page title',
    //   text_help: "I'm not sure what this is lol",
    //   category: 'reviews'
    // },
    // {
    //   reviews_blurb: '',
    //   name: 'reviews_blurb',
    //   type: 'text',
    //   is_location: true,
    //   text: 'Reviews blurb',
    //   text_help: "I'm not sure what this is lol",
    //   category: 'reviews'
    // },
    // {
    //   reviews_link_url: '',
    //   name: 'reviews_link_url',
    //   type: 'text',
    //   is_location: true,
    //   text: 'Reviews link url',
    //   text_help: "I'm not sure what this is lol",
    //   category: 'reviews'
    // },
    // {
    //   reviews_meta_title: '',
    //   name: 'reviews_meta_title',
    //   type: 'text',
    //   is_location: true,
    //   text: 'Reviews meta title',
    //   text_help: "I'm not sure what this is lol",
    //   category: 'reviews'
    // },
    // {
    //   reviews_meta_description: '',
    //   name: 'reviews_meta_description',
    //   type: 'textarea',
    //   is_location: true,
    //   text: 'Reviews meta description',
    //   text_help: "I'm not sure what this is lol",
    //   category: 'reviews'
    // },
    {
      enable_announcement_bar: false,
      name: "enable_announcement_bar",
      type: "boolean",
      is_location: true,
      text: "Enable announcement bar",
      text_help: "",
      category: "announcementBar",
    },
    {
      announcement_bar_title: "",
      name: "announcement_bar_title",
      type: "text",
      is_location: true,
      text: "Optional announcement bar title",
      text_help: "",
      category: "announcementBar",
    },
    {
      announcement_bar_subtitle: "",
      name: "announcement_bar_subtitle",
      type: "text",
      is_location: true,
      text: "Optional announcement bar subtitle",
      text_help: "This text shows up below the title",
      category: "announcementBar",
    },
    {
      announcement_bar_text_color: "",
      name: "announcement_bar_text_color",
      type: "color",
      is_location: true,
      text: "Announcement bar text color",
      text_help: "The color of the font",
      category: "announcementBar",
    },
    {
      announcement_bar_bg_color: "",
      name: "announcement_bar_bg_color",
      type: "color",
      is_location: true,
      text: "Announcement bar background color",
      text_help: "the color of the announcement bar",
      category: "announcementBar",
    },
    {
      announcement_bar_bg_color: "",
      name: "announcement_bar_image",
      type: "image",
      is_location: true,
      text: "Optional image for the announcement bar",
      text_help: "The image shows up on the left",
      category: "announcementBar",
    },
    {
      announcement_bar_link: "",
      name: "announcement_bar_link",
      type: "text",
      is_location: true,
      text: "Optional CTA link for the announcement bar",
      text_help: "The link for an announcement CTA",
      category: "announcementBar",
    },
    {
      announcement_bar_link_text: "",
      name: "announcement_bar_link_text",
      type: "text",
      is_location: true,
      text: "The text for the CTA link",
      text_help: "The text for an announcement CTA link",
      category: "announcementBar",
    },
  ],
  default_settings: {
    has_gate: true,
    has_office: true,
    rental_generate_random_gate_code: true,
    fb_pixel_enabled: true,
    enable_portal: true,
    portal_enable_payments: true,
    portal_enable_prepayments: true,
    portal_enable_autopay: true,
    portal_enable_move_out: true,
    // portal_enable_partial_payments: true,
    portal_payment_disabled_message: "Payments are currently under maintenance. Please call the office to make a payment.",
    portal_num_prepay_months: 12,
    portal_claim_account_tooltip: "Please enter your unit number as it appears on your invoice, including any letters or zeros.",
    enable_reservations: true,
    reservation_days_valid: 7,
    reservation_disabled_message: "Blah blah.",
    enable_rentals: true,
    disable_rentals_on_sunday: false,
    rental_require_insurance: false,
    rental_show_insurance: false,
    rental_insurance_tooltip: "",
    rental_insurance_tooltip_link: "",
    rental_num_days_in_advance: 0,
    rental_show_autopay: true,
    rental_pre_check_autopay: true,
    rental_disabled_message: "Rentals are currently under maintenance. Please call the office to make a payment.",
    rental_show_gate_code_email: false,
    enable_reviews: true,
    reviews_page_title: "",
    reviews_blurb: "",
    reviews_link_url: "",
    reviews_meta_title: "",
    reviews_meta_description: "",
    enable_announcement_bar: false,
    announcement_bar_title: "",
    announcement_bar_subtitle: "",
    announcement_bar_text_color: "#2e2e2e",
    announcement_bar_bg_color: "",
    announcement_bar_link: "",
    announcement_bar_link_text: "",
    announcement_bar_image: null,
    software: "",
    software_vars: {
      corp_code: "",
      corp_username: "",
      license_key: "",
      location_code: "",
      location_pw: "",
    },
    value_pricing: false,
    standard_pricing_description: null,
    best_pricing_description: null,
    deluxe_pricing_description: null,
  },
  settings: [],
})

const getters = {
  getGlobalOptions: (state) => state.options,
  getInsightsOptions: (state) => state.options.filter((opt) => _.isNil(opt.is_insights) || opt.is_insights),
  getDefaultSettings: (state) => state.default_settings,
  getCategories: (state) => state.categories,
  getGlobalOptionsByCategory: (state) => (category) => state.options.filter((option) => option.category === category && (_.isNil(option.is_insights) || !option.is_insights)),
  getGlobalInsightsOptionsByCategory: (state) => (category) => state.options.filter((option) => option.category === category && (_.isNil(option.is_insights) || option.is_insights)),
}

const actions = {
  updateAllSettings({ commit }, payload) {
    commit(UPDATE_SETTINGS, payload)
  },
  removeSettings({ commit }, payload) {
    commit(REMOVE_SETTINGS, payload)
  },
}

const mutations = {
  [UPDATE_SETTINGS](state, payload) {
    state.settings = payload
  },
  [REMOVE_SETTINGS](state, payload) {
    _.remove(state.settings, function (o) {
      return o.id === payload.id
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
