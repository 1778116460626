import * as uuid from "uuid/v1"
import * as _ from "lodash"
import axios from "axios"
import Vue from "vue"
import { recursivelySort } from "@/dashboard/utils/site-builder"
import { ApiFactory } from "@/utils/apis/ApiFactory"
const WebsiteApi = ApiFactory.get("website")
const NavMenuApi = ApiFactory.get("navMenu")
const GlobalStylesApi = ApiFactory.get("globalStyles")

// Mutation Types
const SET_GLOBAL_STYLE = "SET_GLOBAL_STYLE"
const SET_WEBSITE_OPTIONS = "SET_WEBSITE_OPTIONS"
// const SET_GLOBAL_STYLES = 'SET_GLOBAL_STYLES'
const ADD_TO_MENUS = "ADD_TO_MENUS"
const SET_MENUS = "SET_MENUS"
const SET_SELECTED_WEBSITE = "SET_SELECTED_WEBSITE"
const UPDATE_MENU = "UPDATE_MENU"
const DELETE_MENU = "DELETE_MENU"
const CLONE_MENU = "CLONE_MENU"
const UPDATE_SITE_PAGES = "UPDATE_SITE_PAGES"
const UPDATE_GLOBAL_STYLES = "UPDATE_GLOBAL_STYLES"
const UPDATE_WEBSITE_OPTIONS = "UPDATE_WEBSITE_OPTIONS"
const HAS_WEBSITE_CHANGES = "HAS_WEBSITE_CHANGES"
const REDEPLOY_STARTED = "REDEPLOY_STARTED"
const REDEPLOY_FINISHED = "REDEPLOY_FINISHED"
const HAS_NO_WEBSITE_CHANGES = "HAS_NO_WEBSITE_CHANGES"
const SET_AVAILABLE_FONTS = "SET_AVAILABLE_FONTS"

const state = () => ({
  button_style_options: {
    "normal-filled": "",
    "rounded-filled": "rounded-pill",
    "squared-filled": "rounded-0",
    "normal-outline": "btn-outline-*",
    "rounded-outline": "rounded-pill btn-outline-*",
    "squared-outline": "rounded-0 btn-outline-*",
  },
  selected: {
    primaryButton: {},
    secondaryButton: {},
  },
  sitePages: [],
  menus: [],
  fonts_available: [],
  //   {
  //     text: 'Open Sans Family',
  //     value: 'open_sans'
  //   },
  //   {
  //     text: 'Helvetica',
  //     value: 'helvetica'
  //   },
  //   {
  //     text: 'Monteserat',
  //     value: 'Monteserat'
  //   },
  //   {
  //     text: 'Fira Code',
  //     value: 'fira_code'
  //   }
  // ],
  footers_available: [],
  navbars_available: [],
  global_styles: {},
  website_options: [],
  selected_website: {},
})

const getters = {
  buttonStyleOptions: (state) => state.button_style_options,
  getSitePages: (state) => state.sitePages,
  getMenus: (state) => state.menus,
  getSelectedWebsite: (state) => state.selected_website,
  getMenuById: (state) => (id) => {
    return state.menus.find((menu) => menu.id === id)
  },
  getGlobalStyles: (state) => state.global_styles,
  getWebsiteOptions: (state) => {
    return state.website_options
  },
  getWebsiteOptionById: (state) => (id) => {
    return state.website_options.find((website) => website.id === id)
  },
  getAvailableFonts: (state) => state.fonts_available,
  getAvailableFooters: (state) => state.footers_available,
  getAvailableNavbars: (state) => state.navbars_available,
}

const actions = {
  /**
   * Initialize Vuex with website options
   */
  setWebsiteOptions({ rootGetters, commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      let account = rootGetters["user/getSelectedAccountId"]
      const relArray = ["amenity_list", "global_styles", "logo,logo_square", "nav_logo", "favicon", "footer_logo", "nav_component", "footer_component", "main_location"]

      let relations = relArray.join(",")

      if (payload && payload.account) {
        account = payload.account
      }

      if (payload && payload.relations) {
        relations += "," + payload.relations
      }

      try {
        // Make async API call and mutate store
        const { data: response } = await WebsiteApi.all(account, relations)
        const website_payload = response.data

        commit(SET_WEBSITE_OPTIONS, website_payload)
        commit(SET_SELECTED_WEBSITE, website_payload[0])
        return resolve(true)
      } catch (e) {
        Vue.rollbar.error('Store: Error setWebsiteOptions', e)
        return reject(e)
      }
    })
  },
  /**
   * Initialize Vuex with global styles
   */
  // async setGlobalStyles({ commit, rootGetters }, payload) {
  //   let account = rootGetters['user/getSelectedAccountId']
  //   let relations = ''

  //   if (payload && payload.account) {
  //     account = payload.account
  //   }

  //   if (payload && payload.relations) {
  //     relations += ',' + payload.relations
  //   }

  //   // Make async API call and mutate store
  //   const response = await GlobalStylesApi.all(account, relations)
  //   const global_styles_payload = response.data.data

  //   // Mutate store
  //   commit(SET_GLOBAL_STYLES, global_styles_payload)
  // },
  updateButtonsStyle({ commit }, payload) {
    commit(SET_GLOBAL_STYLE, payload)
  },
  updateSitePages({ commit }, payload) {
    commit(UPDATE_SITE_PAGES, payload)
  },
  setMenus({ commit, rootGetters }) {
    return new Promise(async (resolve, reject) => {
      const accountId = rootGetters["user/getSelectedAccountId"]
      const relations = "nav_menu_items,nav_menu_items.children,nav_menu_items.children.children,nav_menu_items.children.children.children,nav_menu_items.children.children.children.children"
      try {
        // Make async API call and mutate store
        const { data: response } = await NavMenuApi.all(accountId, relations)

        // Sort by index
        const clonedMenus = _.cloneDeep(response.data)
        clonedMenus.forEach((menu) => recursivelySort(menu.nav_menu_items))

        // Mutate store
        commit(SET_MENUS, clonedMenus)
        return resolve(true)
      } catch (e) {
        Vue.rollbar.error('Store: Error setting menus', e)
        return reject(e)
      }
    })
  },
  createMenu({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const accountId = rootGetters["user/getSelectedAccountId"]
        const navMenu = {
          account: {
            id: accountId,
          },
        }
        const { data: response } = await NavMenuApi.create(navMenu, accountId)
        const menu = response.data

        commit(ADD_TO_MENUS, [
          {
            id: menu.id,
            is_default: menu.is_default,
            name: menu.name,
            nav_menu_items: menu.nav_menu_items || [],
          },
        ])

        return resolve(menu.id)
      } catch (e) {
        Vue.rollbar.error('Store: Error createMenu', e)
        return reject(e)
      }
    })
  },
  cloneMenu({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const accountId = rootGetters["user/getSelectedAccountId"]
        const { data: response } = await NavMenuApi.create(payload, accountId)
        const menu = response.data

        commit(CLONE_MENU, menu)

        return resolve(menu.id)
      } catch (e) {
        Vue.rollbar.error('Store: Error cloning menu', e, { menu: payload })
        return reject(e)
      }
    })
  },
  deleteMenu({ commit, rootGetters }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const accountId = rootGetters["user/getSelectedAccountId"]

        const { data: response } = await NavMenuApi.delete(id, accountId)

        commit(DELETE_MENU, id)
        return resolve(response)
      } catch (error) {
        Vue.rollbar.error('Store: Error deleteMenu', error, { menu_id: id })
        reject(error)
      }
    })
  },
  /**
   * Update menu in database, and then mutate store with
   * updated values.
   *
   * @todo
   * - Resort by indexes
   */
  updateMenu({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const accountId = rootGetters["user/getSelectedAccountId"]
      try {
        let relations = "nav_menu_items,nav_menu_items.children,nav_menu_items.children.children,nav_menu_items.children.children.children,nav_menu_items.children.children.children.children"
        if (payload.relations) {
          relations = payload.relations
        }
        // Change this to trigger Agenda on Typeorm
        payload.is_update = !payload.is_update
        const { data: response } = await NavMenuApi.update(payload, accountId, relations)
        commit(UPDATE_MENU, response.data)
        return resolve(true)
      } catch (e) {
        Vue.rollbar.error('Store: Error updateMenu', e, { menu: payload })
        return reject(e)
      }
    })
  },
  /**
   * Update global styles of an account
   *
   * @todo
   * - Make async call to update database with updated values
   */
  updateGlobalStyles({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const account = rootGetters["user/getSelectedAccountId"]
        let styles
        if (payload.id) {
          await GlobalStylesApi.update(payload, account)
          styles = payload
        } else {
          const { data: response } = await GlobalStylesApi.save(payload, account)
          styles = response.data
        }
        commit(UPDATE_GLOBAL_STYLES, styles)
        return resolve(true)
      } catch (error) {
        Vue.rollbar.error('Store: Error updateGlobalStyles', error, { styles: payload })
        return reject(error)
      }
    })
  },
  /**
   * Update website options in DB and store
   *
   * @todo
   * - Add async call to update database
   */
  updateWebsiteOptions({ commit, rootGetters }, payload) {
    return new Promise(async (resolve, reject) => {
      const account = rootGetters["user/getSelectedAccountId"]
      // If the object exists, update it. Create it in case it doesn't exist.
      if (payload.id !== undefined && payload.id !== null && payload.id !== "") {
        try {
          const { data: res } = await WebsiteApi.create(payload, account)
          commit(UPDATE_WEBSITE_OPTIONS, res.data)
          return resolve(res.data)
        } catch (e) {
          Vue.rollbar.error('Store: Error updateWebsiteOptions', e, { website_options: payload })
          return reject(e)
        }
      } else {
        try {
          const website = payload
          website.account = {
            id: account,
          }
          const res = await WebsiteApi.create(website, account)
          commit(UPDATE_WEBSITE_OPTIONS, res.data.data)
          return resolve(true)
        } catch (e) {
          console.error(e)
          Vue.rollbar.error('Store: Error updateWebsiteOptions', e, { website_options: payload })
          return reject(e)
        }
      }
    })
  },
  /**
   * Get all available fonts from Google Fonts
   *
   * @TODO
   * - See about filtering by category? i.e. sans-serif fonts only
   */
  setAvailableFonts({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const googlFontsUrl = `https://www.googleapis.com/webfonts/v1/webfonts?key=${ this.$config.google }`
        const { data: google_fonts } = await axios.get(googlFontsUrl)

        // Filter out only the sans-serif fonts
        // const sans_serif = google_fonts.items.map((f) => {
        //   return f.family
        // })
        // const groupedFonts = _.groupBy(google_fonts.items, 'category')
        const categoryFonts = []
        google_fonts.items.forEach((font) => {
          const index = categoryFonts.findIndex((cat) => cat.category === _.startCase(font.category))
          if (index > -1) {
            categoryFonts[index].fonts.push(font.family)
          } else {
            categoryFonts.push({
              category: _.startCase(font.category),
              fonts: [font.family],
            })
          }
        })

        commit(SET_AVAILABLE_FONTS, categoryFonts)
        resolve(google_fonts)
      } catch (error) {
        Vue.rollbar.error('Store: Error updating fonts', error)
        reject(error)
      }
    })
  },
}

const mutations = {
  resetWebsite(state, payload) {
    state.global_styles = {}
    state.selected = {}
    state.website_options = []
    state.navbars_available = []
    state.footers_available = []
    state.sitePages = []
    state.menus = []
    state.selected_website = {}
  },
  /**
   * Set initial website options to store
   */
  [SET_WEBSITE_OPTIONS](state, payload) {
    // const website_options = state.website_options
    state.website_options = [...payload] // [...website_options, ...payload]
  },
  /**
   * Update site pages option list
   */
  [UPDATE_SITE_PAGES](state, payload) {
    const sitePages = state.sitePages
    state.sitePages = [
      ...sitePages,
      {
        ...payload,
      },
    ]
  },
  /**
   * Add new menu to state
   */
  [ADD_TO_MENUS](state, payload) {
    state.menus = [...state.menus, ...payload]
  },
  [SET_MENUS](state, payload) {
    state.menus = payload
  },
  [SET_SELECTED_WEBSITE](state, payload) {
    state.selected_website = payload
  },
  [HAS_WEBSITE_CHANGES](state, payload) {
    state.selected_website.has_new_changes = true
  },
  [REDEPLOY_STARTED](state, payload) {
    state.selected_website.has_redeploy_started = true
  },
  [REDEPLOY_FINISHED](state, payload) {
    state.selected_website.has_redeploy_started = false
  },
  [HAS_NO_WEBSITE_CHANGES](state, payload) {
    state.selected_website.has_new_changes = false
  },
  /**
   * Add cloned post to menus state
   */
  [CLONE_MENU](state, payload) {
    const menus = state.menus
    state.menus.push(payload)
  },
  /**
   * Remove deleted the post from the menus state
   */
  [DELETE_MENU](state, id) {
    const menus = state.menus
    const index = menus.findIndex((menu) => menu.id === id)
    if (index > -1) {
      state.menus.splice(index, 1)
    }
  },
  /**
   * Update menu
   */
  [UPDATE_MENU](state, payload) {
    const menus = state.menus
    // Get menu by id
    const index = menus.findIndex((menu) => menu.id === payload.id)
    // Mutate store
    if (index > -1) {
      state.menus.splice(index, 1, payload)
    } else {
      state.menus.push(payload)
    }
  },
  /**
   * Mutate the store with the updated global styles
   */
  [UPDATE_GLOBAL_STYLES](state, payload) {
    state.website_options[0].global_styles = {
      ...state.website_options[0].global_styles,
      ...payload,
    }
  },
  [UPDATE_WEBSITE_OPTIONS](state, payload) {
    const websites = state.website_options
    const index = websites.findIndex((website) => website.id === payload.id)
    state.website_options.splice(index, 1, payload)
    state.selected_website = payload
  },
  [SET_AVAILABLE_FONTS](state, payload) {
    state.fonts_available = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
